import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit} from "@angular/core";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatDivider} from "@angular/material/divider";
import {UserService} from "../features/user";
import {JsonPipe} from "@angular/common";
import {NotificationService} from "../services/notification.service";
import {AuthService} from "../features/auth/services/auth.service";
import {NavigationService} from "../services/navigation.service";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'auth-menu',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    RouterLink,
    MatDivider,
    JsonPipe,
    MatIconButton
  ],
  template: `
    <button [matMenuTriggerFor]="menu" mat-icon-button>
<!--      <mat-icon>person_outline</mat-icon>-->
      <mat-icon>person</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      @if (isLoggedIn()) {
        <button mat-menu-item>
          <mat-icon>person_outline</mat-icon>
          {{ loggedInName() }}
        </button>
        <mat-divider/>
<!--        <button mat-menu-item>Profil</button>-->
        <button (click)="logout()" mat-menu-item>Logout</button>
      } @else {
        <button [routerLink]="authLink()" mat-menu-item>
          <mat-icon>person_outline</mat-icon>
<!--          [nicht eingeloggt]-->
          Login
        </button>
<!--        <mat-divider/>-->
<!--        <button mat-menu-item>Login as User</button>-->
<!--        @for (user of userList(); track user) {-->
<!--          <button (click)="login(user)" mat-menu-item>-->
<!--            <mat-icon>person_outline</mat-icon>-->
<!--            {{ user.first_name }} {{ user.last_name }}-->
<!--          </button>-->
<!--        }-->
      }
    </mat-menu>
  `,
})
export class AuthMenuComponent implements OnInit {
  @HostBinding('class.auth-menu') classAuthMenu = true;
  private user = inject(UserService);
  private note = inject(NotificationService);
  private nav = inject(NavigationService);
  auth = inject(AuthService);
  isLoggedIn = this.auth.isLoggedIn;
  userList = this.user.items
  loggedInName = this.auth.loggedInName;
  authLink = this.nav.getSegments('auth');

  ngOnInit() {
    // this.user.loadList();
    // this.user.autoLogin(); // debug login
  }

  // login(user: User) {
  //   this.auth.loginUser(user);
  // }

  logout() {
    this.auth.logout();
    this.note.note('Ausgeloggt!');
  }

  navigateToAuth() {
    // this.nav.navigate('auth');
  }
}
