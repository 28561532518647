import {ChangeDetectionStrategy, Component, HostBinding, inject} from '@angular/core';
import {ClientService} from "./client.service";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {AdminClientFormComponent} from "./admin-client-form.component";
import {NavigationService} from "../../services/navigation.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'app-admin-client-detail-page',
  standalone: true,
  imports: [
    AdminPageHeaderComponent,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    AdminClientFormComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <admin-page-header [title]="title"/>
    <div class="toolbar">
      <div class="button-container">
        <button [routerLink]="link$('admin/clients')()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste
        </button>
      </div>
    </div>
    <div class="form-container">
      <admin-client-form/>
    </div>
  `
})
export class AdminClientDetailPageComponent {
  @HostBinding('class.admin-page') classAdminPage = true;

  private nav = inject(NavigationService);
  link$ = this.nav.getSegments;
  private service = inject(ClientService);
  item$ = this.service.currentItem;

  get title(): string {
    return this.item$().id.length > 0
      ? 'Mandant bearbeiten' : 'Mandant erstellen'
  }
}
