import {inject, Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie-service";
import {HashTable} from "../types";

@Injectable({providedIn: 'root'})
export class CookieSessionService {
  private cookieService = inject(CookieService);
  private id: string = 'session-0';
  private state: HashTable<string | boolean | null> = {};
  private initialized = false;

  private commit() {
    this.cookieService.set(this.id, JSON.stringify(this.state), undefined, '/');
  }

  init(id: string = 'session-0'): string {
    if(this.initialized) {
      console.log('CookieSessionService::init()','Session already initialized!');
      return this.id;
    }
    this.id = id;

    if(this.cookieService.check(this.id)) {
      this.state = JSON.parse(this.cookieService.get(this.id)) || {};
    } else {
      this.commit();
    }

    // console.log('CookieSessionService::init().state', this.state);
    this.initialized = true;
    return id;
  }

  clear() {

    if(!this.initialized) {
      console.log('CookieSessionService::clear()','Session not initialized!');
      return;
    }

    // remove any content from session
    this.state = {};
    this.commit();
  }

  setValue(key: string, value: string | boolean | null) {
    if(!this.initialized) {
      console.log('CookieSessionService::set()','Session not initialized!', {key, value});
    }

    this.state[key] = value;
    this.commit();
  }

  getValue(key: string): string | boolean | null {
    if(Object.hasOwn(this.state, key)) {
      return this.state[key];
    }
    return null;
  }
}
