import {HashTable} from "../../../types";
import {I18nLocale} from "../types";

export const locales: HashTable<I18nLocale> = {
  "de": {
    "code": "de",
    "label": "deutsch"
  },
  "en": {
    "code": "en",
    "label": "english"
  }
}
