import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit, signal} from "@angular/core";
import {PageEditorService} from "../../page-editor.service";
import {MatDivider} from "@angular/material/divider";
import {JsonPipe} from "@angular/common";
import {ArticleComponent} from "./article.component";
import {I18nTextInputComponent} from "../../../i18n/i18n-text-input.component";
import {I18nTextareaComponent} from "../../../i18n/i18n-textarea.component";
import {MatButton} from "@angular/material/button";
import {AdminAssetSelectorComponent} from "../../../asset/admin-asset-selector.component";
import {Asset, assetFactory} from "../../../asset";
import {AdminAssetSelectedComponent} from "../../../asset/admin-asset-selected.component";

@Component({
  selector: 'article-editor',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDivider,
    JsonPipe,
    ArticleComponent,
    I18nTextInputComponent,
    I18nTextareaComponent,
    MatButton,
    AdminAssetSelectorComponent,
    AdminAssetSelectedComponent
  ],
  template: `
<!--    ArticleEditorComponent-->
<!--    <mat-divider />-->
<!--    <pre>config: {{component() | json}}</pre>-->
    <div class="form-row">
      <i18n-text-input [(value)]="headline" label="Überschrift" />
    </div>
    <div class="form-row">
      <i18n-textarea [(value)]="text" label="Text" />
    </div>

    @if (hasImage()) {
      <div>Ausgewähltes Bild</div>
      <div class="flex-row">
        <admin-asset-selected [asset]="image()" />
      </div>
    }
<div class="flex-row">
  <button (click)="toggleImageSelector()" mat-button>Bild auswählen</button>
</div>
@if(imageSelectorOpen()) {
  <admin-asset-selector (assetSelected)="onImageSelected($event)"
                        (close)="toggleImageSelector()" />
}
<div class="flex-row">
  <button (click)="save()" mat-button>Übernehmen</button>
</div>
<!--    <mat-divider />-->
<!--    <pre>headline: {{headline() | json}}</pre>-->
<!--    <pre>text: {{text() | json}}</pre>-->

<!--    <article-component [component]="component()" />-->
  `
})
export class ArticleEditorComponent implements OnInit {
  @HostBinding('class.article-editor') dssClass = true;
  pageEditorService = inject(PageEditorService);
  imageSelectorOpen = signal(false);
  toggleImageSelector() {
    this.imageSelectorOpen.update(value => !value);
  }
  component = this.pageEditorService.component;

  headline = signal('{}');
  text = signal('{}');
  image = signal(assetFactory());
  hasImage = computed(() => this.image().id.length > 0);

  save() {
    const component = this.component();
    const updatedComponent = {
      ...component,
      config: {
        ...component.config,
        headline: this.headline(),
        text: this.text(),
        image: this.image()
      }
    }

    this.pageEditorService.updateComponent(updatedComponent);
    this.pageEditorService.navigateToInitializedProject();
  }

  onImageSelected(asset: Asset) {
    console.log('image selected', asset);
    this.image.set(asset);
  }

  ngOnInit() {
    const config = this.component().config || {};
    if(Object.hasOwn(config, 'headline')) {
      this.headline.set(config['headline']);
    }
    if(Object.hasOwn(config, 'text')) {
      this.text.set(config['text']);
    }
    if(Object.hasOwn(config, 'image')) {
      this.image.set(config['image'] as Asset);
    }
  }
}
