{
  "total": 3,
  "items": [
    {
      "id": "t2rewgrgerg",
      "siteId": "t2rewgrgerg",
      "name": "Domain One"
    },
    {
      "id": "gergergerg",
      "siteId": "gergergerg",
      "name": "Domain Two"
    },
    {
      "id": "ahergrwrer",
      "siteId": "ahergrwrer",
      "name": "Domain Tree"
    }
  ]
}
