import {computed, inject, Injectable, signal} from "@angular/core";
import {ApiService} from "../services/api.service";
import {pageConfigFactory, ProjectConfig, ProjectDeploy, projectDeployFactory} from "../features/project";
// import {NavigationService} from "../services/navigation.service";
import {AuthService} from "../features/auth/services/auth.service";

@Injectable({providedIn: 'root'})
export class DeployService {
  private api = inject(ApiService);
  private auth = inject(AuthService);
  // private nav = inject(NavigationService);
  private role = computed(() => this.auth.roles().find(r => r.client_id === this.clientId()));
  isAdmin = computed(() => this.role()?.role_name === 'admin');
  isMod = computed(() => this.role()?.role_name === 'mod');
  isUser = computed(() => this.role()?.role_name === 'user');
  canAccessAdminNavigation = computed(() => this.auth.isSystemAdmin() || this.isAdmin() || this.isMod());

  domain = '';
  deploy = signal(projectDeployFactory());
  config = computed<ProjectConfig>(() => JSON.parse(this.deploy().config) as ProjectConfig);
  clientId = computed(() => this.deploy().client_id);
  projectId = computed(() => this.deploy().project_id);
  hasActiveDeploy = computed(() => this.deploy().id.length > 0);
  page = signal(pageConfigFactory());

  pagePath = computed(() => {
    const pageId = this.page().id;
    const navItem = this.config().navigationNodes.find(n => n.target_page_id === pageId);
    return navItem?.name || '';
  });

  async init() {

    // console.log('ProjectDeployService::init()', this.domain);
    if(!this.domain || `${this.domain}`.length < 1) {
      console.log('ProjectDeployService::init()', 'No domain config data for deploy!', 'App run for administration only.');
      return;
    }

    try {
      const deploy = await this.api.fetch<ProjectDeploy>(`project-by-domain/${this.domain}`);
      this.deploy.set(deploy);
      console.log('ProjectDeployService::init()', 'Config load for domain.', `[${this.domain}]`);
      // console.log('ProjectDeployService::init()', 'Config load for domain.', this.projectDeploy());
    } catch (error) {
      console.log('ProjectDeployService::init()::deploy:error', error);
    }
  }
}





