import {computed, Inject, inject, Injectable, signal, WritableSignal} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Site, siteFactory} from "./index";
import {firstValueFrom} from "rxjs";
import {ListResponse, ListFilter} from "../../types";
import {CanActivateFn} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {mockSiteListResponse} from "./mock";
import {Domain} from "../domain";

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private http = inject(HttpClient);
  public currentItem$: WritableSignal<Site> = signal(siteFactory());

  public filter$ = signal<ListFilter>({
    search: '',
    page: 0,
    size: 10,
    orderBy: 'label',
    direction: 'asc'
  });

  private listResponse$ = signal<ListResponse<Site>>({
    items: [],
    total: 0
  });
  public items$ = computed(() => this.listResponse$().items);
  public total$ = computed(() => this.listResponse$().total);

  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter$().search)
      .set('page', `${this.filter$().page}`)
      .set('size', `${this.filter$().size}`);
  }
  loadList() {

    if(environment.noBackend) {
      this.listResponse$.set(mockSiteListResponse);
      return;
    }

    firstValueFrom(this.http.get('/api/site', {params: this.listParams}))
      .then(value => this.listResponse$.set(value as ListResponse<Site>))
      .catch(error => console.error('SiteService::loadList()', error));
  }

  async loadItem(id: string): Promise<Site> {
    try {
      return await firstValueFrom(this.http.get<Site>(`/api/site/${id}`));
    } catch {
      return this.loadItemJson(id);
    }
  }

  private async loadItemJson(id: string) {
    if(environment.noBackend) {
      return Promise.resolve<Site>(
        this.items$().find(item => item.id === id) || siteFactory()
      );
    }
    return firstValueFrom(this.http.get<Site>(`/api/site/${id}`));
  }

  async save(item: Site): Promise<Site> {
    if(item.id.length > 0) {
      return firstValueFrom(this.http.put<Site>(`/api/site/${item.id}`, item));
    } else {
      return firstValueFrom(this.http.post<Site>(`/api/site`, item));
    }
  }
}

export const canEditSite: CanActivateFn = async (route, state) => {
  const service = inject(SiteService);
  try {
    const item = await service.loadItem(`${route.paramMap.get('id')}`);
    service.currentItem$.set(item);
  } catch {
    service.currentItem$.set(siteFactory());
  }
  return true;
};

export const canCreateSite: CanActivateFn = (route, state) => {
  inject(SiteService).currentItem$.set(siteFactory());
  return true;
};
