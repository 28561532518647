import {ChangeDetectionStrategy, Component, computed, HostBinding, inject} from "@angular/core";
import {AdminMapEntryDetailFormComponent} from "./admin-map-entry-detail-form.component";
import {RouterLink} from "@angular/router";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {NavigationService} from "../../services/navigation.service";
import {MapEntryService} from "./map-entry.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    AdminMapEntryDetailFormComponent,
    RouterLink,
    MatButton,
    MatIcon,
  ],
  template: `
    <admin-page-header [title]="title()"/>
    <div class="toolbar">
      <div class="button-container">
        <button [routerLink]="link('admin/map-entries')()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste
        </button>
      </div>
    </div>
    <div class="form-container">
      <admin-map-entry-detail-form />
    </div>
  `
})
export class AdminMapEntryDetailPageComponent {
  @HostBinding('class.admin-page') dssClass = true;
  private nav = inject(NavigationService);
  private service = inject(MapEntryService);
  link = this.nav.getSegments;
  item = this.service.currentItem;

  title = computed(() =>
    this.item().id.length > 0
      ? 'Beitrag bearbeiten'
      : 'Beitrag erstellen'
  );
}
