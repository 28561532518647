import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {Asset, assetFactory} from "./types";

@Component({
  selector: 'app-asset-thumb, [app-asset-thumb]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `AssetThumbComponent`,
  styles: [``]
})
export class AssetThumbComponent {
  @Input() asset: Asset = assetFactory();
}
