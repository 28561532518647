import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit} from "@angular/core";
import {RouterLink} from "@angular/router";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {NavigationService} from "../../services/navigation.service";
import {NotificationService} from "../../services/notification.service";
import {AppService} from "../../services/app.service";
import {MapEntryService} from "./map-entry.service";
import {MapTagService} from "./map-tag.servie";
import {MapEntry} from "./types";
import {JsonPipe} from "@angular/common";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";
import {AdminService} from "../../admin/admin.service";

@Component({
  selector: 'admin-map-entry-detail-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    RouterLink,
    MatButton,
    MatIcon,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    JsonPipe
  ],
  template: `
    <div class="form-row">
      <mat-form-field  appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="moderationStatusControl">
          <mat-option value="pending">Wartend</mat-option>
          <mat-option value="approved">Freigegeben</mat-option>
          <mat-option value="rejected">Abgelehnt</mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field  appearance="outline">
        <mat-label>Anrede</mat-label>
        <mat-select [formControl]="authorSalutationControl">
          <mat-option></mat-option>
          <mat-option value="dr">Dr.</mat-option>
          <mat-option value="prof">Prof.</mat-option>
          <mat-option value="prof_dr">Prof. Dr.</mat-option>
          <mat-option value="dr_jur">Dr. jur.</mat-option>
          <mat-option value="dr_dr">Dr. Dr.</mat-option>
          <mat-option value="prof_dr_dr">Prof. Dr. Dr.</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Vorname</mat-label>
        <input [formControl]="authorFirstNameControl" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nachname</mat-label>
        <input [formControl]="authorLastNameControl" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input [formControl]="authorEmailControl" matInput />
      </mat-form-field>
    </div>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label>Adresse</mat-label>
          <input [formControl]="authorAddressControl" matInput />
        </mat-form-field>
      </div>

    <div class="mat-form-field-full-container">
      <mat-form-field appearance="outline">
        <mat-label>Überschrift</mat-label>
        <input [formControl]="requestTitleControl" matInput />
      </mat-form-field>
    </div>
    <div class="mat-form-field-full-container">
      <mat-form-field appearance="outline">
        <mat-label>Text</mat-label>
        <textarea [formControl]="requestDescriptionControl" rows="6" matInput></textarea>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Tags</mat-label>
        <mat-select [formControl]="tagsControl" multiple>
          @for (tag of tags(); track tag) {
            <mat-option [value]="tag.id">{{tag.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="button-container">
      <button (click)="save()" mat-raised-button>Speichern</button>
      @if(!isNew()) {
        <button (click)="delete()" mat-raised-button>Löschen</button>
      }
    </div>
  `
})
export class AdminMapEntryDetailFormComponent implements OnInit {
  @HostBinding('class.admin-map-entry-detail-form') dssClass = true;
  private nav = inject(NavigationService);
  private note = inject(NotificationService);
  private service = inject(MapEntryService);
  private adminService = inject(AdminService);
  link = this.nav.getSegments;
  item = this.service.currentItem;
  isNew = computed(() => this.item().id.length < 1);

  private pinboardTagService = inject(MapTagService);
  private app = inject(AppService);
  tags = this.pinboardTagService.items;

  authorSalutationControl = new FormControl('');
  authorFirstNameControl = new FormControl('');
  authorLastNameControl = new FormControl('');
  authorEmailControl = new FormControl('');
  authorAddressControl = new FormControl('');
  requestTitleControl = new FormControl('');
  requestDescriptionControl = new FormControl('');
  tagsControl = new FormControl<Array<string>>([]);
  moderationStatusControl = new FormControl('pending');

  save() {
    let mValue = `${this.moderationStatusControl.value}`;
    if(!['pending','rejected','approved'].includes(mValue)) {
      mValue = 'pending'
    }
    const newItem: MapEntry = {
      ...this.item(),
      client_id: this.adminService.clientId(),
      tag_ids: this.tagsControl.value || [],
      author_salutation: this.authorSalutationControl.value || '',
      author_first_name: this.authorFirstNameControl.value || '',
      author_last_name: this.authorLastNameControl.value || '',
      author_email: this.authorEmailControl.value || '',
      author_address: this.authorAddressControl.value || '',
      request_title: this.requestTitleControl.value || '',
      request_description: this.requestDescriptionControl.value || '',
      moderation_status: (mValue) as 'pending' | 'rejected' | 'approved',
    };

    console.log('save item', newItem);

    this.service.save(newItem)
      .then(this.success)
      .catch(this.fail);
  }

  success = () => {
    this.note.note('Gespeichert!');
    this.nav.navigate('admin/map-entries');
  }

  fail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }

  delete = () => {
    this.note.confirm("Eintrag Löschen?", () => {
      this.service.deleteById(this.item().id)
        .then(this.deleteSuccess)
        .catch(this.deleteFail);
    });
  }

  deleteSuccess = () => {
    this.note.note('Gelöscht!');
    this.nav.navigate('admin/pinboard');
  }

  deleteFail = () => {
    this.note.note('Löschen fehlgeschlagen!');
  }

  ngOnInit() {
    const item = this.item();
    console.log('init item MapEntry', item);
    this.authorSalutationControl.setValue(item.author_salutation, {emitEvent: false});
    this.authorFirstNameControl.setValue(item.author_first_name, {emitEvent: false});
    this.authorLastNameControl.setValue(item.author_last_name, {emitEvent: false});
    this.authorEmailControl.setValue(item.author_email, {emitEvent: false});
    this.authorAddressControl.setValue(item.author_address, {emitEvent: false});
    this.requestTitleControl.setValue(item.request_title, {emitEvent: false});
    this.requestDescriptionControl.setValue(item.request_description, {emitEvent: false});
    this.moderationStatusControl.setValue(item.moderation_status, {emitEvent: false});
    this.tagsControl.setValue(item.tag_ids, {emitEvent: false});
  }
}
