import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {NavigationService} from "../../services/navigation.service";
import {MapEntryService} from "./map-entry.service";
import {MapEntry} from "./types";
import {RouterLink} from "@angular/router";
import {JsonPipe} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";

@Component({
  selector: 'admin-map-entry-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    RouterLink,
    JsonPipe
  ],
  template: `
    <table mat-table [dataSource]="items()">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Titel</th>
        <td mat-cell *matCellDef="let item"> {{ item.request_title }}</td>
      </ng-container>

      <ng-container matColumnDef="moderation_status">
        <th mat-header-cell *matHeaderCellDef> Status</th>
        <td mat-cell *matCellDef="let item">{{ moderationState(item) }}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell class="actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <button mat-button
                  [routerLink]="detailLink$(item.id)()">
            <mat-icon>search</mat-icon>
            Details
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `
})
export class AdminMapEntryListComponent {
  private nav = inject(NavigationService);
  private service = inject(MapEntryService);
  detailLink$ = (id: string) => this.nav.getSegments(`admin/map-entry/${id}`);
  items = this.service.items;
  public displayedColumns: string[] = ['title', 'moderation_status', 'id'];

  moderationState(item: MapEntry): string {
    if (item.moderation_status === 'approved') {
      return 'Freigegeben';
    } else if (item.moderation_status === 'rejected') {
      return 'Abgelehnt';
    } else {
      return 'Wartend';
    }
  }
}

