import {Injectable, signal} from "@angular/core";
import {Font} from "./types";

@Injectable({providedIn:"root"})
export class FontService {
  defaultFont = signal<Font>({
    key: 'Roboto',
    value: "'Roboto', sans-serif",
    label: 'Roboto (default)'
  });
  fonts = signal<Array<Font>>([
    this.defaultFont(),
    {
      key: 'Playpen Sans',
      value: "'Playpen Sans', sans-serif",
      label: 'Playpen Sans'
    },
  ]);
}
