import {computed, inject, Injectable, signal} from '@angular/core';
import {User, userFactory, UserRole, UserService} from "../../user";
import {NavigationService} from "../../../services/navigation.service";
import {ApiService} from "../../../services/api.service";
import {CookieService} from "ngx-cookie-service";
import {CanActivateFn} from "@angular/router";
import {CookieSessionService} from "../../../services/cookie-session.service";
import {AdminService} from "../../../admin/admin.service";
import {DeployService} from "../../../deploy/deploy.service";
import {KeycloakService} from "keycloak-angular";
import {AppService} from "../../../services/app.service";
import {PaKeycloakService} from "./pa-keycloak.service";
import {PaAuthResponse, RegisterCheckRequest, RegisterFinalizeRequest, RegisterRequest} from "../types";
import {CheckResponse} from "../../../types";
import {response} from "express";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private app = inject(AppService);
  private nav = inject(NavigationService);
  private api = inject(ApiService);
  private cookieService = inject(CookieService);
  private userService = inject(UserService);
  private session = inject(CookieSessionService);
  private keycloakService = inject(KeycloakService);
  private paKeycloakService = inject(PaKeycloakService);
  private kcEnabled = signal(environment.keycloak.enabled);

  user = signal(userFactory());
  isLoggedIn = computed(() => this.user().id.length > 0);
  isSystemAdmin = computed(() => this.user().is_system_admin);
  roles = signal<Array<UserRole>>([]);
  rolesForAdminAccess = computed(() => this.roles().filter(role => ['admin', 'mod'].includes(role.role_name)));

  loggedInName = computed(() => {
    if (!this.isLoggedIn()) {
      return 'Gast';
    }
    const first_name = this.user().first_name;
    const last_name = this.user().last_name;

    if(first_name.length > 0 && last_name.length > 0) {
      return `${first_name} ${last_name}`;
    } else if(first_name.length > 0) {
      return first_name;
    } else if(last_name.length > 0) {
      return last_name;
    }

    return this.user().email;
  });

  async loginCheck(email: string, password: string) {
    return this.api.post<User>('user/login', {email, password});
  }

  async loginUser(user: User) {
    this.user.set(user);
    this.api.userId.set(user.id);
    this.nav.reset();
    this.session.setValue('login_user_id', user.id);
    const userRoles = await this.userService.fetchRoles(user.id);
    this.roles.set(userRoles);
  }

  private logoutReset() {
    // console.log('AuthService::logoutReset()');
    this.session.setValue('login_user_id', null);
    this.nav.reset();
    this.user.set(userFactory());
    this.roles.set([]);
    this.api.userId.set('');
  }

  async logout() {
    // console.log('logout');
    this.logoutReset();
    this.nav.navigate('');
  }

  async init() {
    // console.log('auth init');
    if(environment.keycloak.enabled) {
      await this.paKeycloakService.init();

      if(this.keycloakService.isLoggedIn()) {
        console.log('kc user is logged in! Fetch user data');
        // TODO enable, as soon as i have the endpoint
        try {
          this.api.token.set(this.paKeycloakService.token());
          const response = await this.api.fetch<PaAuthResponse>('user-auth-info', true);
          this.user.set(response.user);
          this.roles.set(response.roles);
        } catch (e) {
          console.log('kk token invalid', e);
          this.kcLogout();
          this.logoutReset();
          this.api.token.set('');
        }
        // const response = await this.api.fetch<PaAuthResponse>('/user-auth-info');
        // this.user.set(response.user);
        // this.roles.set(response.roles);
      }
      // this.api.token.set(this.paKeycloakService.token());
      return;
    } else {
      this.api.token.set('');
    }

    // remove old cookies => remove this next two lines some time later
    this.cookieService.delete('login_user_id', '/');
    this.cookieService.delete('login_user_id', '/de');

    const userId = this.session.getValue('login_user_id');
    if('string' !== typeof userId || userId.length < 1) {
      // console.log('User not logged in!');
      return;
    }

    try {
      const user = await this.userService.fetchById(userId);
      await this.loginUser(user);
    } catch (error) {
      console.log('user session error', error);
      this.logoutReset();
    }
  }

  kcLogin(): void {
    if(!this.app.isBrowser()) {
      console.log('Error: Try to kc login on not browser platform!');
      return;
    }

    // Keycloak does not use "location.assign" but rather "location.replace"; so it does not itself create a history
    //  entry to which one could navigate back. For this reason, we're creating one manually.
    window.history.pushState({}, 'Landing Page');
    this.keycloakService.login({
      redirectUri: `${window.location.origin}/`,
    });
  }

  kcLogout(): void {
    if(!this.app.isBrowser()) {
      console.log('Error: Try to kc login on not browser platform!');
      return;
    }
    console.log('Im logging out for kc');
    this.keycloakService.logout(
      `${window.location.origin}/`
    ).then(result => console.log('after logout', result));
  }
}

export const canActivateAdminView: CanActivateFn = (route, state) => {

  const adminService = inject(AdminService);
  const deployService = inject(DeployService);
  const authService = inject(AuthService);

  if(deployService.hasActiveDeploy()) {
    return deployService.canAccessAdminNavigation();
  }

  return adminService.canAccessAdminNavigation() || authService.rolesForAdminAccess().length > 0;
}
