import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, signal} from "@angular/core";
import {DynamicNavigationNodeComponent} from "./dynamic-navigation-node.component";
import {PageEditorService} from "../page-editor/page-editor.service";
import {DeployService} from "../../deploy/deploy.service";

@Component({
  selector: 'dynamic-navigation',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DynamicNavigationNodeComponent
  ],
  template: `
    @for (node of nodes(); track node.id) {
      <dynamic-navigation-node [node]="node" [isDeployView]="isDeployView()"/>
    }
  `,
})
export class DynamicNavigationComponent {
  @HostBinding('class.dynamic-navigation') cssClass = true;
  pageEditorService = inject(PageEditorService);
  isDeployView = input(false);
  deploy = inject(DeployService);
  nodes = computed(() => this.isDeployView()
    ? this.deploy.config().navigationNodes
    : this.pageEditorService.config().navigationNodes);
}
