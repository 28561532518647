import {Route} from "@angular/router";
import {DeployLayoutComponent} from "./deploy-layout.component";
import {
  PinboardEntryDeployDisplayPageComponent
} from "../features/pinboard/pinboard-entry-deploy-display-page.component";
import {canReadPinboardEntry} from "../features/pinboard/pinboard-entry.service";
import {DeployDefaultPageComponent} from "./deploy-default-page.component";
import {canViewDeploy, canViewDeployPage, canViewDeployPageFirst} from "./guards";

export const deployRoute: Route = {
  path: '', component: DeployLayoutComponent, canActivate: [canViewDeploy], children: [
    {
      path: 'pinboard-entry-display/:entry_id',
      component: PinboardEntryDeployDisplayPageComponent,
      canActivate: [canReadPinboardEntry]
    },
    {
      path: ':page', canActivate: [canViewDeployPage], children: [
        {
          path: 'pinboard-entry-display/:entry_id',
          component: PinboardEntryDeployDisplayPageComponent,
          canActivate: [canReadPinboardEntry]
        },
        {
          path: '', children: [
            {
              path: 'pinboard-entry-display/:entry_id',
              component: PinboardEntryDeployDisplayPageComponent,
              canActivate: [canReadPinboardEntry]
            },
            {path: '', component: DeployDefaultPageComponent}
          ]
        },
      ]
    },
    {path: '', component: DeployDefaultPageComponent, canActivate: [canViewDeployPageFirst]},
  ]
};
