import {computed, inject, Injectable, signal, WritableSignal} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {ListResponse,ListFilter} from "../../types";
import {CanActivateFn} from "@angular/router";
import {Domain, domainFactory} from "./index";
import {mockDomainListResponse} from "./mock";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  private http = inject(HttpClient);
  public currentItem$: WritableSignal<Domain> = signal(domainFactory());

  public filter$ = signal<ListFilter>({
    search: '',
    page: 0,
    size: 10,
    orderBy: 'name',
    direction: 'asc'
  });

  private listResponse$: WritableSignal<ListResponse<Domain>> = signal<ListResponse<Domain>>({
    items: [],
    total: 0
  });
  public items$ = computed(() => this.listResponse$().items);
  public total$ = computed(() => this.listResponse$().total);

  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter$().search)
      .set('page', `${this.filter$().page}`)
      .set('size', `${this.filter$().size}`);
  }
  loadList() {
    if(environment.noBackend) {
      this.listResponse$.set(mockDomainListResponse);
      return;
    }
    firstValueFrom(this.http.get('/api/domain', {params: this.listParams}))
      .then(value => this.listResponse$.set(value as ListResponse<Domain>))
      .catch(error => console.error('DomainService::loadList()', error));
  }

  async loadItem(id: string): Promise<Domain> {
    if(environment.noBackend) {
      return Promise.resolve<Domain>(
        this.items$().find(item => item.id === id) || domainFactory()
      );
    }
    return await firstValueFrom(this.http.get<Domain>(`/api/domain/${id}`));
  }

  async save(item: Domain): Promise<Domain> {
    if(item.id.length > 0) {
      return firstValueFrom(this.http.put<Domain>(`/api/domain/${item.id}`, item));
    } else {
      return firstValueFrom(this.http.post<Domain>(`/api/domain`, item));
    }
  }
}

export const canEditDomain: CanActivateFn = async (route, state) => {
  const service = inject(DomainService);
  try {
    const item = await service.loadItem(`${route.paramMap.get('id')}`);
    service.currentItem$.set(item);
  } catch {
    service.currentItem$.set(domainFactory());
  }
  return true;
};

export const canCreateDomain: CanActivateFn = (route, state) => {
  inject(DomainService).currentItem$.set(domainFactory());
  return true;
};
