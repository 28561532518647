{
  "total": 3,
  "items": [
    {
      "id": "t2rewgrgerg",
      "label": "Site One",
      "title": "Site One"
    },
    {
      "id": "gergergerg",
      "label": "Site Two",
      "title": "Site Two"
    },
    {
      "id": "ahergrwrer",
      "label": "Site Tree",
      "title": "Site Tree"
    }
  ]
}
