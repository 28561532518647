import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
  selector: 'dev-block',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `#`,
  styles: [`:host {
    display: block;
    overflow: hidden;
    position: relative;
    height: 350px;
    width: 350px;
    outline: solid 1px #333333;
    background-color: #CCCCCC;
    color: #CCCCCC;
    user-select: none;
  }`]
})
export class DevBlockComponent {
}
