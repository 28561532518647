import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {UserService} from "./user.service";
import {JsonPipe} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {User} from "./index";
import {MatCheckbox} from "@angular/material/checkbox";
import {NotificationService} from "../../services/notification.service";
import {NavigationService} from "../../services/navigation.service";
import {AuthService} from "../auth/services/auth.service";

@Component({
  selector: 'app-user-form',
  standalone: true,
  imports: [
    JsonPipe,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckbox,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-row fields-container">
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Vorname</mat-label>
          <input matInput [formControl]="firstNameControl"/>
          @if (!firstNameControl.valid) {
            <mat-error>Name mit nim 6 Zeichen benötigt!</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Nachname</mat-label>
          <input matInput [formControl]="lastNameControl"/>
          @if (!lastNameControl.valid) {
            <mat-error>Name mit nim 6 Zeichen benötigt!</mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row fields-container">
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="emailControl"/>
          @if (!emailControl.valid) {
            <mat-error>Valide Email-Adresse benötigt!</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Telefon</mat-label>
          <input matInput [formControl]="phoneControl"/>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row fields-container">
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Postleitzahl</mat-label>
          <input matInput [formControl]="addressPostalControl"/>
        </mat-form-field>
      </div>
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Strasse</mat-label>
          <input matInput [formControl]="addressStreetControl"/>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row fields-container">
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Stadt</mat-label>
          <input matInput [formControl]="addressStreetControl"/>
        </mat-form-field>
      </div>
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Land</mat-label>
          <input matInput [formControl]="addressCountryControl"/>
        </mat-form-field>
      </div>
    </div>
<!--    @if (auth.isSystemAdmin()) {-->

<!--      <div class="flex flex-row fields-container">-->
<!--        <div class="flex-1 field-container">-->
<!--          <mat-form-field appearance="outline">-->
<!--            <mat-label>Password</mat-label>-->
<!--            <input matInput [formControl]="passwordControl" type="password"/>-->
<!--            @if (!passwordControl.valid) {-->
<!--              <mat-error>Mindestlänge 6 zeichen!</mat-error>-->
<!--            }-->
<!--          </mat-form-field>-->
<!--        </div>-->
<!--      </div>-->
<!--    }-->

    @if (auth.isSystemAdmin()) {
      <div class="flex-row">
        <mat-checkbox [formControl]="isSystemAdminControl">Superadmin</mat-checkbox>
      </div>
    }

    <div class="flex flex-row overflow-clip button-container">
      <div class="flex-grow"></div>
      <div>
        <button mat-raised-button
                (click)="submit()"
                color="primary">Speichern
        </button>
      </div>
    </div>
  `
})
export class AdminUserFormComponent {
  private note = inject(NotificationService);
  private nav = inject(NavigationService);
  private service = inject(UserService);
  auth = inject(AuthService);
  item = this.service.currentItem;


  isSystemAdminControl = new FormControl<boolean>(this.item().is_system_admin);
  firstNameControl = new FormControl<string>(this.item().first_name);
  lastNameControl = new FormControl<string>(this.item().last_name);
  // passwordControl = new FormControl<string>(this.item().password_tmp,[
  //   Validators.required,
  //   Validators.minLength(6)
  // ]);
  emailControl = new FormControl<string>({value: this.item().email, disabled: true});
  phoneControl = new FormControl<string>(this.item().phone);
  addressCountryControl = new FormControl<string>(this.item().address_country);
  addressCityControl = new FormControl<string>(this.item().address_city);
  addressPostalControl = new FormControl<string>(this.item().address_postal);
  addressStreetControl = new FormControl<string>(this.item().address_street);

  form = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    addressCountry: this.addressCountryControl,
    addressCity: this.addressCityControl,
    addressPostal: this.addressPostalControl,
    addressStreet: this.addressStreetControl,
  });

  submit() {

    if(!this.form.valid) {
      this.form.markAllAsTouched();
      this.note.note('Eingabe überprüfen!');
      return;
    }

    this.service.save({
      ...this.item(),
      first_name: this.firstNameControl.value as string,
      last_name: this.lastNameControl.value as string,
      // email: this.emailControl.value as string,
      phone: this.phoneControl.value as string,
      address_country: this.addressCountryControl.value as string,
      address_city: this.addressCityControl.value as string,
      address_zip: this.addressPostalControl.value as string,
      address_street: this.addressStreetControl.value as string,
      // password_tmp: this.passwordControl.value as string,
      is_system_admin: !!this.isSystemAdminControl.value,
    } as User).then(this.success)
      .catch(this.fail);
  }

  private success = () => {
    this.note.note('Speichern erfolgreich!');
    this.nav.navigate('admin/users');
  }

  private fail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }
}
