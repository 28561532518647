import {inject, Injectable, signal} from "@angular/core";
import {RegisterCheckRequest, RegisterFinalizeRequest, RegisterRequest} from "../types";
import {CheckResponse} from "../../../types";
import {ApiService} from "../../../services/api.service";

@Injectable({providedIn: 'root'})
export class AuthRegisterService {
  private api = inject(ApiService);
  registerKey = signal('');
  checkResponse = signal<CheckResponse>({success: false});

  register(data: RegisterRequest) {
    return this.api.post<CheckResponse>('user-register', data);
  }

  registerCheckKey(data: RegisterCheckRequest) {
    return this.api.post<CheckResponse>('user-register-check', data);
  }

  registerFinalize(data: RegisterFinalizeRequest) {
    return this.api.post<CheckResponse>('user-register-finalize', data);
  }
}
