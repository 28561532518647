import {computed, inject, Injectable, signal} from "@angular/core";
import {Client, clientFactory, ClientService} from "../features/client";
import {CookieSessionService} from "../services/cookie-session.service";
import {AuthService} from "../features/auth/services/auth.service";

@Injectable({providedIn: 'root'})
export class AdminService {
  private session = inject(CookieSessionService);
  private auth = inject(AuthService);
  private clientService = inject(ClientService);
  private client = signal(clientFactory());

  clientId = computed(() => this.client().id);
  clientName = computed(() => this.client().name);

  role = computed(() => this.auth.roles().find(r => r.client_id === this.clientId()));
  isAdmin = computed(() => this.role()?.role_name === 'admin');
  isMod = computed(() => this.role()?.role_name === 'mod');
  // isUser = computed(() => this.role()?.name === 'user'); // never used?
  canAccessAdminNavigation = computed(() => this.auth.isSystemAdmin() || this.isAdmin() || this.isMod());
  canAccessSettingsAdministration = computed(() => this.auth.isSystemAdmin() || this.isAdmin() || this.isMod());
  canAccessUserAdministration = computed(() => this.auth.isSystemAdmin() || this.isAdmin() || this.isMod());

  setClient(client: Client) {
    this.client.set(client);
    this.session.setValue('admin_client_id', client.id);
  }

  async init() {
    const clientId = this.session.getValue('admin_client_id');
    // console.log('AdminService::init()');
    // console.log('AdminService::init()', {clientId});
    if ('string' === typeof clientId && clientId.length > 0) {
      try {
        const client = await this.clientService.fetchById(clientId);
        // console.log('AdminService::init()', {client});
        this.setClient(client);
      } catch(error) {
        this.session.setValue('admin_client_id', null);
      }
    }
  }
}

