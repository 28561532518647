import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, Input} from "@angular/core";
import {JsonPipe} from "@angular/common";
import {MatDivider} from "@angular/material/divider";
import {MarkdownComponent} from "ngx-markdown";
import {PinboardEntry, pinboardEntryFactory} from "./types";

@Component({
  selector: 'pinboard-entry-display',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    JsonPipe,
    MatDivider,
    MarkdownComponent
  ],
  template: `
    <div class="pinboard-entry-title">{{title()}}</div>
    <div class="flex-row gap">
      <div class="flex">
        {{author()}}
      </div>
      <div>{{created()}}</div>
    </div>
    <mat-divider />
    <div class="pinboard-entry-content">
      <markdown [data]="content()" />
    </div>
  `
})
export class PinboardEntryDisplayComponent {
  @HostBinding('class.pinboard-entry-display') dssClass = true;
  entry = input<PinboardEntry>(pinboardEntryFactory());
  title = computed(() => this.entry().title);
  author = computed(() => this.entry().author_name);
  created = computed(() => this.entry().created);
  content = computed(() => this.entry().content);
}
