import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {isValidPassword} from "./is-valid-password";

export const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (!isValidPassword(value)) {
    return { invalidPassword: true };
  }
  return null;
};
