import {Inject, inject, Injectable, makeStateKey, Optional, PLATFORM_ID, TransferState} from "@angular/core";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {ApiService} from "./api.service";
import {AuthService} from "../features/auth/services/auth.service";
import {clientFactory, ClientService} from "../features/client";
import {CanActivateFn} from "@angular/router";
import {DeployService} from "../deploy/deploy.service";
import {CookieSessionService} from "./cookie-session.service";
import {AdminService} from "../admin/admin.service";
import {KeycloakService} from "keycloak-angular";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class AppInitService {
  private platformId = inject(PLATFORM_ID);
  private api = inject(ApiService);
  private auth = inject(AuthService);
  private clientService = inject(ClientService);
  private adminService = inject(AdminService);
  private deployService = inject(DeployService);
  private keycloakService = inject(KeycloakService);

  // Create unique state keys
  private API_URL_KEY = makeStateKey<string>('apiUrl');
  private FORWARDED_HOST_KEY = makeStateKey<string>('forwardedHost');
  private HEADERS_INFO_KEY = makeStateKey<any>('headersInfo');

  constructor(
    @Optional() @Inject('API_URL') private apiUrl: string,
    @Optional() @Inject('FORWARDED_HOST') private forwardedHost: string,
    @Optional() @Inject('HEADERS_INFO') private headers: any,
    private transferState: TransferState
  ) {

    // TransferState for SSR
    if (isPlatformServer(this.platformId)) {
      // Store server-side data in TransferState
      this.transferState.set(this.API_URL_KEY, this.apiUrl);
      this.transferState.set(this.FORWARDED_HOST_KEY, this.forwardedHost);
      this.transferState.set(this.HEADERS_INFO_KEY, this.headers);

      // Leave this here. It's for local docker testing.
      // this.apiUrl = 'http://host.docker.internal:8080/api';

    } else if (isPlatformBrowser(this.platformId)) {

      // Retrieve client-side data from TransferState
      this.apiUrl = this.transferState.get(this.API_URL_KEY, null) || '';
      this.forwardedHost = this.transferState.get(this.FORWARDED_HOST_KEY, null) || '';
      this.headers = this.transferState.get(this.HEADERS_INFO_KEY, null) || '';
    }

    // debug entry
    // this.forwardedHost = 'cms-fe.local';

    // console.log("Value of apiUrl is: ", typeof this.apiUrl, this.apiUrl);
    // console.log("Value of forwardedHost is: ", typeof this.forwardedHost, this.forwardedHost);
    // console.log("Value of header object is: ", typeof this.headers, this.headers);

    if(!this.apiUrl || this.apiUrl.length < 1) {
      console.log('Warning: x-api-url not set! Using environment.apiUrl', environment.apiUrl);
      this.apiUrl = environment.apiUrl;
    }

    this.deployService.domain = this.forwardedHost;
  }

  async init() {
    this.api.xApiUrl = this.apiUrl;
    await this.clientService.fetchList();
    await this.deployService.init();
    await this.adminService.init();
    await this.auth.init();
    this.initAdminClient();
  }

  private initAdminClient() {
    if(this.adminService.clientId().length > 0) {
      return;
    }
    if(this.deployService.hasActiveDeploy()) {
      this.adminService.setClient(this.getClientById(this.deployService.clientId()));
      return;
    }

    const roles = this.auth.rolesForAdminAccess();
    if(this.auth.isSystemAdmin()) {
      let defaultClient = this.clientService.items().find(client => client.name === 'default');
      if(defaultClient) {
        this.adminService.setClient(defaultClient);
      } else {
        const emptyClient = clientFactory();
        emptyClient.name = '---';
        this.adminService.setClient(emptyClient);
      }
    } else if(roles.length > 0) {
      this.adminService.setClient(this.getClientById(roles[0].client_id));
    }

  }

  private getClientById(clientId: string) {
    return this.clientService.items().find(client => client.id === clientId) || clientFactory();
  }
}

export const canActivateInitializedApp: CanActivateFn = async (route, state) => {
  console.log('canActivateInitializedApp()::start');
  inject(CookieSessionService).init('pa-session');
  await inject(AppInitService).init();
  console.log('canActivateInitializedApp()::end');
  return true;
}

// WARNING This is executed at build time too
export const initializeAppFactory = () => async () => {
  // console.log('APP_INITIALIZER');
  return true;
};
