import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {DomainService} from "./domain.service";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-domain-list',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    RouterLink
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <table mat-table [dataSource]="items$()">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let item"> {{item.name}} </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <button mat-button
                  [routerLink]="detailLink$(item.id)()">
            <mat-icon>search</mat-icon>
            Details
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `
})
export class AdminDomainListComponent implements OnInit {
  private nav = inject(NavigationService);
  detailLink$ = (id: string) => this.nav.getSegments(`admin/domain/${id}`);
  private service = inject(DomainService);
  public items$ = this.service.items$;
  public displayedColumns: string[] = ['name', 'id'];
  ngOnInit() {
    this.service.loadList();
  }
}
