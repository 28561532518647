import {computed, inject, Injectable, Pipe, PipeTransform, Signal, signal} from '@angular/core';
import {locales, translations} from "./data";
import {CanActivateFn, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  locales = signal(locales);
  defaultLocale = signal(locales[environment.defaultLocaleCode]);
  locale = signal(locales[environment.defaultLocaleCode]);
  private translations$ = computed(() => translations[this.locale().code]);

  translate(key: string): string {
    if(key in this.translations$()) {
      return this.translations$()[key];
    }
    return `[${key}]`;
  }

  extract = (content: string, fallback: string = '[no-value]') => computed(() => {
    const c = JSON.parse(content) || {};
    const localeCode = this.locale().code;

    if(Object.hasOwnProperty.call(c, localeCode)) {
      return c[localeCode] as string;
    }

    const defaultLocaleCode = this.defaultLocale().code;
    if(Object.hasOwnProperty.call(c, defaultLocaleCode)) {
      return c[defaultLocaleCode]  as string;
    }

    return fallback;
  });

  public translate$(key: string): Signal<string> {
    return computed(() => {
      if(key in this.translations$()) {
        return this.translations$()[key];
      }
      return `[${key}]`;
    });
  }

  setLocale(lang: string) {
    this.locale.set(locales[lang]);
  }
}

export const canActivateI18n: CanActivateFn = (route, state) => {

  const router = inject(Router);
  const i18n = inject(I18nService);
  const urlLocale: string = route.params['locale'];

  const codes = ['de', 'en']; // TODO calculate

  if(`${urlLocale}`.length < 1 || !codes.some(item => item === urlLocale)) {
    router.navigate([`/${environment.defaultLocaleCode}`])
      .then(() => console.log('Navigated to default language.'));
    return false;
  }

  if(i18n.locale().code !== urlLocale) {
    i18n.setLocale(urlLocale);
  }

  return true;
};


@Pipe({
  name: 'translate',
  standalone: true
})
export class TranslatePipe implements PipeTransform {
  private i18n = inject(I18nService);
  transform(key: string): Signal<string> {
    return this.i18n.translate$(key);
  }
}
