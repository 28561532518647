import {ChangeDetectionStrategy, Component, HostBinding, inject, input, output} from "@angular/core";
import {AssetService} from "./asset.service";
import {AdminAssetCardComponent} from "./admin-asset-card.component";
import {Asset} from "./types";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'admin-asset-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AdminAssetCardComponent
  ],
  template: `
    @for (item of items(); track item) {
      <admin-asset-card [asset]="item"
                        [selectable]="selectable()"
                        [selectType]="selectType()"
                        (selected)="assetSelected.emit($event)"
                        (delete)="deleteAsset($event)" />
    }
  `
})
export class AdminAssetListComponent {
  @HostBinding('class.admin-asset-list') cssClass = true;
  selectable = input(false);
  selectType = input<'all' | 'images'>('all')
  assetSelected = output<Asset>();
  private service = inject(AssetService);
  private note = inject(NotificationService);
  items = this.service.items;

  deleteAsset = (asset: Asset) => {
    this.service.deleteById(asset.id)
      .then(this.deleteAssetSuccess)
      .catch(this.deleteAssetFailure);
  }

  deleteAssetSuccess = () => {
    console.log('asset deleted');
    this.note.note('Gelöscht!');
    this.service.fetchList();
  }
  deleteAssetFailure = () => {
    console.log('asset delete failed');
    this.note.note('Löschen fehlgeschlagen!');
  }
}
