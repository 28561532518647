import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, Input} from '@angular/core';
import {AppService} from "../services/app.service";
import {AdminService} from "./admin.service";

@Component({
  selector: 'admin-page-header',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="info-container">Aktiver Mandant: {{activeClientName()}}</div>
    <div class="title">{{title}}</div>
  `,
})
export class AdminPageHeaderComponent {
  @HostBinding('class.admin-page-header') classAdminPage = true;
  @Input() title: string = '';
  private admin = inject(AdminService);
  activeClientName = this.admin.clientName;
}
