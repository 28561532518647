import {ChangeDetectionStrategy, Component, HostBinding} from "@angular/core";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'admin-settings-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent
  ],
  template: `
    <admin-page-header title="Einstellungen"/>
    coming soon..
  `
})
export class AdminSettingsPageComponent {
  @HostBinding('class.admin-page') cssClass = true;
}
