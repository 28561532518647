import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isValidUrlSegment} from "./is-valid-url-segment";

export const urlSegmentValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (!isValidUrlSegment(value)) {
    return {invalidUrlSegment: true};
  }
  return null;
};
