import {inject, Injectable} from "@angular/core";
import {CanActivateFn} from "@angular/router";
import {PageEditorService} from "../page-editor/page-editor.service";

@Injectable({providedIn: 'root'})
export class RichMapService {

}

export const canEditRichMap: CanActivateFn = async (route, state) => {
  const pageEditorService = inject(PageEditorService);
  const components = pageEditorService.page().components;
  const componentId = `${route.paramMap.get('component_id')}`;
  const component = components.find(component => component.id === componentId);
  if(component !== undefined) {
    pageEditorService.component.set(component);
    return true;
  } else {
    console.log('component id not found');
    return false
  }
}
