import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  input,
  OnInit,
  output,
  signal
} from "@angular/core";
import {PageEditorService} from "../page-editor/page-editor.service";
import {MapTagService} from "./map-tag.servie";
import {JsonPipe} from "@angular/common";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {RichMapContainerComponent} from "./rich-map-container.component";
import {MatButton} from "@angular/material/button";

@Component({
  selector: "rich-map-editor",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    JsonPipe,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    RichMapContainerComponent,
    MatButton
  ],
  template: `

    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Tags</mat-label>
        <mat-select [formControl]="tagsControl" multiple>
          @for (tag of tags(); track tag) {
            <mat-option [value]="tag.id">{{tag.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <div>
        Lat: {{center().latlng.lat}}<br />
        Lng: {{center().latlng.lng}}<br />
        Zoom: {{center().zoom}}
      </div>
      <rich-map-container [component]="component()"
                          (onSelectCenter)="onSelectCenter($event)"
                          [isEditorMode]="true" />
    </div>
    <button (click)="save()" mat-raised-button>Speichern</button>
  `
})
export class RichMapEditorComponent implements OnInit {
  @HostBinding('class.rich-map-editor') dssClass = true;
  private pageEditorService = inject(PageEditorService);
  private tagsService = inject(MapTagService);
  afterSave = output<void>();
  component = this.pageEditorService.component;
  config = computed(() => this.component().config);
  tags = this.tagsService.items;

  tagsControl = new FormControl<Array<string>>([]);

  center = signal({
    // latlng: [52.5200, 13.4050], // Berlin
    // latlng: [48.73336, 9.31976], // Esslingen
    latlng: {lat: 48.73336, lng: 9.31976}, // Esslingen
    zoom: 12
  });

  onSelectCenter = (event: any) => {
    console.log('onSelectCenter', event);
    this.center.set(event);
  }

  save() {
    console.log('saved this.center()', this.center());
    this.component.update(() => ({ ...this.component(), config: {
        ...this.config(),
        tag_ids: this.tagsControl.value,
        center: this.center(),
    }}));
    this.pageEditorService.updateComponent(this.component());
    this.afterSave.emit();
  }

  ngOnInit() {
    this.tagsService.filter.update(filter => ({...filter, projectId: this.pageEditorService.project().id}))
    this.tagsService.loadList();

    // this.tagsControl.valueChanges.subscribe((value) => {
      // this.component.set({ ...this.component(), tags: value });
    // });

    const config = this.component().config;
    if ( Object.hasOwn(config, 'tag_ids')) {
      this.tagsControl.setValue(config['tag_ids'], {emitEvent: false});
    }

    if ( Object.hasOwn(config, 'center')) {
      this.center.set(config['center']);
    }
  }
}
