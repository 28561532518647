import {HasId, ListFilter, ModerationStatus} from "../../types";

export interface MapEntryListFilter extends ListFilter {
  clientId: string;
  projectId: string;
  moderationStatus: ModerationStatus | '';
  tagIds: string[];
}

export const mapEntryListFilterFactory = (): MapEntryListFilter => ({
  search: '',
  clientId: '',
  projectId: '',
  moderationStatus: '',
  page: 0,
  size: 50,
  orderBy: 'created',
  direction: 'desc',
  tagIds: []
});

export interface MapTagListFilter extends ListFilter {
  projectId: string;
  clientId: string;
}

export const mapTagListFilterFactory = ():MapTagListFilter  => ({
  search: '',
  clientId: '',
  projectId: '',
  page: 0,
  size: 50,
  orderBy: 'created',
  direction: 'desc'
});

export interface MapEntry extends HasId {
  id: string;
  created: string;
  modified: string;
  client_id: string;
  project_id: string;
  tag_ids: Array<string>;
  moderation_status: ModerationStatus;
  author_salutation: string;
  author_first_name: string;
  author_last_name: string;
  author_email: string;
  author_address: string;
  author_consent: boolean;
  request_title: string;
  request_description: string;
  config: string;
}

export const mapEntryFactory = (): MapEntry => ({
  id: '',
  created: '',
  modified: '',
  client_id: '',
  project_id: '',
  tag_ids: [],
  moderation_status: 'pending',
  author_salutation: '',
  author_first_name: '',
  author_last_name: '',
  author_email: '',
  author_address: '',
  author_consent: true,
  request_title: '',
  request_description: '',
  config: '{}',
});

export interface MapTag extends HasId {
  id: string;
  client_id: string;
  project_id: string;
  created: string;
  modified: string;
  name: string;
  config: string;
}

export const mapTagFactory = (): MapTag => ({
  id: '',
  client_id: '',
  project_id: '',
  created: '',
  modified: '',
  name: '',
  config: '',
});
