import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {Asset} from "./types";

@Component({
  selector: 'app-asset-list, [app-asset-list]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `AssetDisplayComponent`,
  styles: [``]
})
export class AssetListComponent {
  @Input() assetList: Array<Asset> = [];
}
