import {ChangeDetectionStrategy, Component, computed, HostBinding, inject} from "@angular/core";
import {DynamicSiteHeaderComponent} from "../navigation/dynamic-site-header.component";
import {PinboardEntryDisplayComponent} from "./pinboard-entry-display.component";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {DeployService} from "../../deploy/deploy.service";
import {NavigationService} from "../../services/navigation.service";
import {RouterLink} from "@angular/router";
import {PinboardEntryService} from "./pinboard-entry.service";

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DynamicSiteHeaderComponent,
    PinboardEntryDisplayComponent,
    MatButton,
    MatIcon,
    RouterLink
  ],
  template: `
    <div class="page-content-container">
      <div class="toolbar">
        <div class="button-container">
          <button [routerLink]="backLink()" mat-button>
            <mat-icon>arrow_back</mat-icon>
            Zurück
          </button>
        </div>
      </div>
    </div>

    <div class="page-content-container">
      <pinboard-entry-display [entry]="entry()" />
    </div>

    <div class="page-content-container">
        <div class="flex-row">
          <div class="flex"></div>
          <div>
            <button mat-raised-button disabled>Antworten</button>
          </div>
        </div>
    </div>
  `
})
export class PinboardEntryDeployDisplayPageComponent {
  @HostBinding('class.pinboard-entry-display-page') classAdminPage = true;
  private deploy = inject(DeployService);
  private nav = inject(NavigationService);
  private service = inject(PinboardEntryService);
  entry = this.service.currentItem;
  backLink = computed(() => this.nav.getSegments(this.deploy.pagePath())());
}
