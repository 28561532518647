import {APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideHttpClient, withFetch} from "@angular/common/http";
import {provideMarkdown} from "ngx-markdown";
import {initializeAppFactory} from "./lib/services/app-init.service";
import {KeycloakService} from "keycloak-angular";
import {environment} from "../environments/environment";

// console.log('environment', environment);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    KeycloakService,
    // { // coz its not provided in 'root'
    //   provide: KeycloakService,
    //   useClass: KeycloakService
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      // deps: [AppInitService, KeycloakService, HttpClient],
    },
    provideMarkdown(),
  ]
};
