import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit} from "@angular/core";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";
import {PinboardEntryCommentService} from "./pinboard-entry-comment.service";
import {AdminPinboardEntryDetailFormComponent} from "./admin-pinboard-entry-detail-form.component";
import {AdminPinboardEntryCommentDetailFormComponent} from "./admin-pinboard-entry-comment-detail-form.component";

@Component({
  selector: 'admin-pinboard-entry-comment-detail-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    RouterLink,
    MatButton,
    MatIcon,
    AdminPinboardEntryDetailFormComponent,
    AdminPinboardEntryCommentDetailFormComponent,
  ],
  template: `
    <admin-page-header [title]="title()"/>
    <div class="toolbar">
      <div class="button-container">
        <button [routerLink]="commentsLink()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste
        </button>
      </div>
    </div>
    <div class="form-container">
      <admin-pinboard-entry-comment-detail-form />
    </div>
  `
})
export class AdminPinboardEntryCommentDetailPageComponent {
  @HostBinding('class.admin-page') dssClass = true;
  private nav = inject(NavigationService);
  private service = inject(PinboardEntryCommentService);
  private entry = this.service.entry;
  commentsLink = this.nav.getSegments(
    `admin/pinboard-entry/${this.entry().id}/comments`
  );
  title = computed(() => `Beitrag: ${this.entry().title}`);
}
