import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, signal} from "@angular/core";
import {ComponentConfig, componentConfigFactory} from "../../../project";
import {MatDivider} from "@angular/material/divider";
import {JsonPipe} from "@angular/common";
import {I18nService} from "../../../i18n";
import {Asset, AssetDisplayComponent, assetFactory, AssetService} from "../../../asset";
import {MarkdownComponent} from "ngx-markdown";

@Component({
  selector: 'article-component',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDivider,
    JsonPipe,
    AssetDisplayComponent,
    MarkdownComponent
  ],
  template: `
    <div class="headline">{{headline()}}</div>
    <div class="article-content">
      <div class="image-container">
        @if(hasImage()) {
          <img [src]="imgSrc()" alt="" />
        }
<!--        @else {-->
<!--          <div>kein Bild ausgewählt</div>-->
<!--        }-->
      </div>
      <div class="text-container">
        <markdown [data]="text()" />
      </div>
    </div>
<!--    <div>-->
<!--      ArticleComponent-->
<!--    </div>-->
<!--    <div>Headline: {{headline()}}</div>-->
<!--    <div>text: {{text()}}</div>-->
<!--    <div>image: {{hasImage()}}</div>-->
<!--    <mat-divider />-->
<!--    <pre>config: {{component() | json}}</pre>-->
<!--    <mat-divider />-->
<!--    <pre>locale: {{locale() | json}}</pre>-->
<!--    <mat-divider />-->
  `
})
export class ArticleComponent {
  @HostBinding('class.article-component') dssClass = true;
  isDeployView = input(false);
  private i18n = inject(I18nService);
  private assetService = inject(AssetService);
  // locale = this.i18n.locale;
  component = input<ComponentConfig>(componentConfigFactory());
  config = computed(() => {
    const component = this.component();
    const config = Object.hasOwn(component, 'config') ? component['config'] : {};
    if(!Object.hasOwn(config, 'headline')) {
      config['headline'] = JSON.stringify({});
    }
    if(!Object.hasOwn(config, 'text')) {
      config['text'] = JSON.stringify({});
    }
    if(!Object.hasOwn(config, 'image')) {
      config['image'] = assetFactory();
    }
    console.log('article config', config);
    return config;
  });
  image = computed(() => this.config()['image'] as Asset);
  hasImage = computed(() => this.image().id.length > 0);
  imgSrc = computed(() => this.assetService.getDisplayUrl(this.image()));

  headline = computed(() => this.i18n.extract(this.config()['headline'], '')());
  text = computed(() => this.i18n.extract(this.config()['text'])());

}
