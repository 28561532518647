import {computed, inject, Injectable, signal} from "@angular/core";
import {
  PinboardEntry,
  PinboardEntryComment,
  pinboardEntryCommentFactory,
  pinboardEntryCommentListFilterFactory,
  pinboardEntryFactory
} from "./types";
import {ApiService} from "../../services/api.service";
import {AppService} from "../../services/app.service";
import {listResponseFactory, ModerationStatus} from "../../types";
import {HttpParams} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class PinboardEntryCommentService {
  private api = inject(ApiService);
  private app = inject(AppService);
  private listResponse = signal(listResponseFactory<PinboardEntryComment>());
  currentItem = signal(pinboardEntryCommentFactory());
  filter = signal(pinboardEntryCommentListFilterFactory());
  items = computed(() => this.listResponse().items);
  total = computed(() => this.listResponse().total);
  entry = signal(pinboardEntryFactory());
  setEntry(entry: PinboardEntry) {
    this.entry.set(entry);
    this.filterSetClientId(entry.client_id);
    this.filterSetProjectId(entry.project_id);
    this.filterSetPinboardEntryId(entry.id);
  }
  private get listParams(): HttpParams {
    // this.filter.update(filter => ({...filter, clientId: this.app.activeClient().id}));
    return new HttpParams()
      .set('search', this.filter().search)
      .set('client_id', `${this.filter().clientId}`)
      .set('project_id', `${this.filter().projectId}`)
      .set('pinboard_entry_id', `${this.filter().pinboardEntryId}`)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`)
      .set('moderation_status', `${this.filter().moderationStatus}`)
      ;
  }

  filterSetClientId(id: string) {
    this.filter.update(filter => ({...filter, clientId: id}));
  }

  filterSetProjectId(projectId: string) {
    this.filter.update(filter => ({...filter, projectId}));
  }

  filterSetPinboardEntryId(pinboardEntryId: string) {
    this.filter.update(filter => ({...filter, pinboardEntryId}));
  }

  filterSetSearch(search: string) {
    this.filter.update(filter => ({...filter, search}));
  }

  filterSetModerationStatus(moderationStatus: ModerationStatus | '') {
    this.filter.update(filter => ({...filter, moderationStatus}));
  }

  async loadList() {
    const response = await this.api.fetchList<PinboardEntryComment>('pinboardentrycomment', this.listParams);
    this.listResponse.set(response);
    return response;

  }

  async fetchItem(id: string){
    return this.api.fetchById<PinboardEntryComment>('pinboardentrycomment', id);
  }

  async save(item: PinboardEntryComment): Promise<PinboardEntryComment> {
    return this.api.save<PinboardEntryComment>('pinboardentrycomment', item);
  }

  async deleteById(id: string){
    return this.api.deleteById<PinboardEntryComment>('pinboardentrycomment', id);
  }
}
