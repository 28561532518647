import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {PinboardEntryCommentService} from "./pinboard-entry-comment.service";
import {PinboardEntryComment} from "./types";
import {JsonPipe} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MatTableModule} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {NavigationService} from "../../services/navigation.service";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'admin-pinboard-entry-comment-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    JsonPipe,
    MatButton,
    MatIcon,
    MatTableModule,
    RouterLink,
  ],
  template: `
    <table mat-table [dataSource]="items()">
      <ng-container matColumnDef="author_name">
        <th mat-header-cell *matHeaderCellDef>Author</th>
        <td mat-cell *matCellDef="let item"> {{ item.author_name }}</td>
      </ng-container>

      <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef>Inhalt</th>
        <td mat-cell *matCellDef="let item">{{ contentSnippet(item) }}</td>
      </ng-container>

      <ng-container matColumnDef="moderation_status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let item">{{ moderationState(item) }}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell class="actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <div class="flex-row">
            <button mat-button
                    [routerLink]="detailLink(item.id)()">
              <mat-icon>search</mat-icon>
              Details
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `
})
export class AdminPinboardEntryCommentListComponent {
  private service = inject(PinboardEntryCommentService);
  private nav = inject(NavigationService);

  private entry = this.service.entry;
  public items = this.service.items;
  public displayedColumns: string[] = ['author_name', 'content', 'moderation_status', 'id'];
  detailLink = (id: string) =>
    this.nav.getSegments(`admin/pinboard-entry/${this.entry().id}/comment/${id}`
  );

  moderationState(item: PinboardEntryComment): string {
    if (item.moderation_status === 'approved') {
      return 'Freigegeben';
    } else if (item.moderation_status === 'rejected') {
      return 'Abgelehnt';
    } else {
      return 'Wartend';
    }
  }

  contentSnippet(item: PinboardEntryComment): string {
    return `${item.content.substring(0, 25)}...`
  }
}
