import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input} from "@angular/core";
import {Asset, assetFactory} from "./types";
import {AssetService} from "./asset.service";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'admin-asset-selected',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIcon
  ],
  template: `
    <div class="symbol">
      @if (isImage()) {
        <div class="image-container">
          <img [src]="src()" alt="-"/>
        </div>
      } @else if (isPdf()) {
        <div class="asset-as-icon">
          <mat-icon>picture_as_pdf</mat-icon>
        </div>
      } @else {
        <div class="asset-as-icon">
          <mat-icon>file_copy</mat-icon>
        </div>
      }
    </div>
    <div class="filename">{{asset().name}}</div>
  `
})
export class AdminAssetSelectedComponent {
  @HostBinding('class.admin-asset-selected') dssClass = true;
  asset = input(assetFactory());
  private service = inject(AssetService);
  hasAsset = computed(() => this.asset().id.length > 0);
  isImage = computed(() => this.service.assetTypesImage.some(type => this.asset().content_type === type));
  isPdf = computed(() => this.asset().content_type === this.service.assetTypesPdf);
  src = computed(() => this.service.getDisplayUrl(this.asset() as Asset));
}
