import {ChangeDetectionStrategy, Component, HostBinding, inject} from "@angular/core";
import {FormBuilder, FormControl, ReactiveFormsModule} from "@angular/forms";
import {User} from "../../user";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {AuthService} from "../services/auth.service";
import {NotificationService} from "../../../services/notification.service";
import {NavigationService} from "../../../services/navigation.service";
import {AdminService} from "../../../admin/admin.service";
import {DeployService} from "../../../deploy/deploy.service";
import {ClientService} from "../../client";

@Component({
  selector: 'login-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatButton,
  ],
  template: `
    <div class="form-container">
      <div class="form-title">Login</div>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="emailControl" required />
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input (keydown.enter)="login()"
                 [formControl]="passwordControl"
                 type="password"
                 matInput required/>
        </mat-form-field>
      </div>
      <div class="form-action-container">
        <button (click)="login()" mat-raised-button>Login</button>
      </div>
    </div>
  `
})
export class AuthLoginFormComponent {
  @HostBinding('class.login-form') dssClass = true;
  private auth = inject(AuthService);
  private note = inject(NotificationService);
  private nav = inject(NavigationService);
  private clientService = inject(ClientService);
  private adminService = inject(AdminService);
  private deployService = inject(DeployService);
  private formBuilder = inject(FormBuilder);
  emailControl = new FormControl('', []);
  passwordControl = new FormControl('');
  private form = this.formBuilder.group( {
    email: this.emailControl,
    password: this.passwordControl,
  })

  login() {

    this.note.note('Hier kein Login mehr.. Das existiert nur noch als Relikt und wird gelöscht!');

    // if(!this.form.valid) {
    //   this.note.note('Bitte Email und Passwort eintragen!');
    //   return;
    // }
    //
    // this.auth.loginCheck(
    //   `${this.emailControl.value}`,
    //   `${this.passwordControl.value}`)
    //   .then(this.loginSuccess)
    //   .catch(this.loginFail);
  }

  loginSuccess = async (user: User) => {
    // console.log('login check success', user);
    await this.auth.loginUser(user);
    this.adminService.setClient(this.getClientForLogin(this.getClientIdForLogin()));
    this.note.note('Login erfolgreich!');
    this.nav.navigate('');
  }

  private getClientIdForLogin() {
    if(this.deployService.hasActiveDeploy()) {
      return this.deployService.clientId();
    }

    const roles  = this.auth.rolesForAdminAccess()
    return roles.length > 0 ? roles[0].client_id : null;
  }

  private getClientForLogin(clientId: string | null) {
    if(!clientId) {
      return this.clientService.defaultClient();
    }

    return this.clientService.items()
      .find(item => item.id === clientId)
      || this.clientService.defaultClient();
  }

  loginFail = () => {
    this.note.note('Login fehlgeschlagen!');
  }
}
