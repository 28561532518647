import {computed, inject, Injectable, signal} from "@angular/core";
import {CanActivateFn} from "@angular/router";
import {
  ComponentConfig,
  componentConfigFactory, PageConfig,
  pageConfigFactory,
  Project, projectConfigFactory,
} from "../project";
import {entityArrayShiftItemDown, entityArrayShiftItemUp, uuid} from "../../functions";
import {DynamicNavigationNode, dynamicNavigationNodeFactory} from "../navigation/types";
import {NavigationService} from "../../services/navigation.service";
import {ProjectService} from "../project/project.service";

@Injectable({providedIn: 'root'})
export class PageEditorService {
  nav = inject(NavigationService);
  projectService = inject(ProjectService);
  project = this.projectService.currentItem;
  projectId = computed(() => this.project().id);
  config = signal(projectConfigFactory());
  page = signal(pageConfigFactory());
  component = signal(componentConfigFactory());

  addComponent(component: ComponentConfig) {
    component.id = uuid();
    console.log('addComponent', component);
    this.page.update(page => ({
      ...page,
      components: [...page.components, component]
    }));
    this.updatePage(this.page());
  }

  addNode(node: DynamicNavigationNode) {
    console.log('addNode', node);
    this.config.update(config => ({
      ...config,
      navigationNodes: [...config.navigationNodes, node]
    }));
  }

  updateNode(node: DynamicNavigationNode) {
    console.log('updateNode', node);
    this.config.update(config => ({
      ...config,
      navigationNodes: config.navigationNodes.map(n => n.id !== node.id ? n : node)
    }));
  }

  deleteNode(node: DynamicNavigationNode) {
    console.log('deleteNode', node);
    this.config.update(config => ({
      ...config,
      navigationNodes: config.navigationNodes.filter(n => n.id !== node.id)
    }));
  }

  addPage(page: PageConfig) {
    console.log('addPage', page);
    this.config.update(config => ({
      ...config,
      pages: [...config.pages, page]
    }));
  }

  updatePage(page: PageConfig) {
    this.config.update(config => ({
      ...config,
      pages: config.pages.map(p => p.id === page.id ? page : p)
    }));
  }

  deletePage(page: PageConfig) {
    this.config.update(config => ({
      ...config,
      pages: config.pages.filter(p => p.id !== page.id)
    }));
  }

  updateComponent(component: ComponentConfig) {
    // console.log('Service::updateComponent', component);

    this.page.update(page => ({
      ...page,
      components: page.components.map(c => c.id === component.id ? component : c)
    }));
    this.updatePage(this.page());
  }

  moveNavigationNodeUp(node: DynamicNavigationNode) {
    const nodes = this.config().navigationNodes;
    const index = nodes.findIndex(item => item.id === node.id);
    if(index < 2) {
      return;
    }
    entityArrayShiftItemUp(nodes, node);
    this.config.update(config => ({
      ...config,
        navigationNodes: nodes,
    }));
  }

  moveNavigationNodeDown(node: DynamicNavigationNode) {
    const nodes = this.config().navigationNodes;
    entityArrayShiftItemDown(nodes, node);
    this.config.update(config => ({
      ...config,
        navigationNodes: nodes,
    }));
  }

  doesNavigationNameExist(name: string): boolean {
    return this.config().navigationNodes.some(n => n.name === name);
  }

  moveComponentUp(component: ComponentConfig) {
    const components = this.page().components;
    entityArrayShiftItemUp(components, component);
    this.page.update(page => ({
      ...page,
      components: components,
    }));
    this.updatePage(this.page());
  }

  moveComponentDown(component: ComponentConfig) {
    const components = this.page().components;
    entityArrayShiftItemDown(components, component);
    this.page.update(page => ({
      ...page,
      components: components,
    }));
    this.updatePage(this.page());
  }

  deleteComponent(component: ComponentConfig) {
    this.page.update(page => ({
      ...page,
      components: page.components.filter(c => c.id !== component.id)
    }));
    this.updatePage(this.page());
  }

  createNewPage() {
    const page = pageConfigFactory();
    page.id = uuid();
    this.config.update(config => ({
      ...config,
      pages: [...config.pages, page]
    }))
    return page;
  }

  getPageSegments = (pageId: string) => {
    return this.nav.getSegments(`admin/page-editor/${this.project().id}/${pageId}`);
  }

  navigateToComponent(component: ComponentConfig) {
    const projectId = this.project().id;
    const pageId = this.page().id;
    const path = `admin/component-editor/${projectId}/${pageId}/${component.type}/${component.id}`;
    console.log('component_path', path);
    return this.nav.navigate(path);
  }

  navigateToNavigationEditor() {
    const projectId = this.project().id;
    const pageId = this.page().id;
    const path = `admin/navigation-editor/${projectId}/${pageId}`;
    return this.nav.navigate(path);
  }

  navigateToInitializedProject() {
    const projectId = this.project().id;
    const pageId = this.page().id;
    const path = `admin/page-editor/${projectId}/${pageId}`;
    return this.nav.navigate(path);
  }

  initProject(project: Project) {
    this.project.set(project);
    this.config.set(JSON.parse(project.config));
    const pages = this.config().pages;

    if (pages.length > 0) {
      this.page.set(pages[0]);

      // check for first navigation node
      if (this.config().navigationNodes.length < 1) {
        const node = dynamicNavigationNodeFactory();
        node.id = uuid();
        node.target_page_id = pages[0].id;
        this.addNode(node);
        this.save();
      }

    } else {

      const firstPage = this.createNewPage();
      this.page.set(firstPage);
      this.addPage(firstPage);

      const node = dynamicNavigationNodeFactory();
      node.id = uuid();
      node.label = JSON.stringify({de: 'Home', en: 'Home'});
      node.target_page_id = firstPage.id;
      this.addNode(node);

      this.save(); // TODO @desian: Find a better way, if time is there
    }
    this.component.set(componentConfigFactory());
  }

  save() {
    return this.projectService.save({
      ...this.project(),
      config: JSON.stringify(this.config())
    });
  }
}

export const canEditProjectPages: CanActivateFn = async (route, state) => {
  const pageEditorService = inject(PageEditorService);
  const projectService = inject(ProjectService);
  // const nav = inject(NavigationService);
  const projectId = `${route.paramMap.get('project_id')}`;
  const pageId = `${route.paramMap.get('page_id')}`;
  // console.log('canEditProjectPages, projectId', projectId);
  // console.log('canEditProjectPages, pageId', pageId);

  if (projectId.length < 1 || pageId.length < 1) {
    console.log('failing reading project_id or page_id');
    return false;
  }

  try {
    if (pageEditorService.project().id !== projectId) {

      const project = await projectService.fetchItem(projectId);
      pageEditorService.initProject(project);
    }

    if (pageEditorService.page().id === pageId) {
      return true; // correct page selected
    }

    const pages = pageEditorService.config().pages;
    // pages.forEach(page => console.log('page', page, page.id === pageId));
    if (!pages.some(page => page.id === pageId)) {
      return false; // invalid link
    }

    // pageEditorService.page.set(pages.find(page => page.id === pageId)); // editor cant figure it out...
    const page = pages.find(page => page.id === pageId);
    if (page !== undefined) {
      pageEditorService.page.set(page);
    }

  } catch (error) {
    console.log('Error', error);
    return false;
  }

  return true;
};
