import {HasId} from "../../types";

export interface Domain extends HasId {
  id: string;
  siteId: string;
  name: string;
}

export const domainFactory = (): Domain => ({
  id: '',
  siteId: '',
  name: ''
});
