import {ChangeDetectionStrategy, Component, HostBinding, inject} from "@angular/core";
import {FormBuilder, FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'rich-map-comment-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule
  ],
  template: `
    <h1>Kommentar hinzufügen</h1>

    <div class="flex flex-column fields-container">
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="emailControl"/>
          @if (!emailControl.valid) {
            <mat-error>Valide Email-Adresse benötigt!</mat-error>
          }
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-column fields-container">
      <div class="flex-1 field-container comment">
        <mat-form-field appearance="outline">
          <mat-label>Kommentar</mat-label>
<!--          <input matInput [formControl]="commentControl"/>-->
          <textarea matInput [formControl]="commentControl"></textarea>
          @if (!commentControl.valid) {
            <mat-error>Kommentar kann nicht leer sein!!</mat-error>
          }
        </mat-form-field>
      </div>
    </div>

  `
})
export class RichMapCommentFormComponent {
  @HostBinding('class.form-container') classRichFormContainer = true;
  @HostBinding('class.rich-map-comment-form') classRichMapCommentForm = true;
  formBuilder = inject(FormBuilder);
  commentControl = new FormControl('');
  emailControl = new FormControl('');
  form = this.formBuilder.group({
    commentControl: this.commentControl,
    emailControl: this.emailControl,
  });
}
