import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {ClientService} from "./client.service";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {RouterLink} from "@angular/router";
import {Client} from "./types";
import {NavigationService} from "../../services/navigation.service";
import {AuthService} from "../auth/services/auth.service";
import {AdminService} from "../../admin/admin.service";

@Component({
  selector: 'app-admin-client-list',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    RouterLink
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <table mat-table [dataSource]="availableClients()">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let item"> {{ item.name }}</td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <div class="flex-row">
            @if (isActive(item)()) {
              <button mat-button>
                <mat-icon>edit</mat-icon>
                Aktiv
              </button>
            } @else {
              <button (click)="activateClient(item)" mat-button>
                <mat-icon>edit</mat-icon>
                Aktivieren
              </button>
            }
            <button mat-button
                    [routerLink]="detailLink(item.id)()">
              <mat-icon>search</mat-icon>
              Details
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `
})
export class AdminClientListComponent {
  private adminService = inject(AdminService);
  private nav = inject(NavigationService);
  private auth = inject(AuthService);
  detailLink = (id: string) => this.nav.getSegments(`admin/client/${id}`);
  private service = inject(ClientService);
  public items = this.service.items;
  public displayedColumns: string[] = ['name', 'id'];
  public availableClients = computed(() =>  {
    if(this.auth.isSystemAdmin()) {
      return this.items();
    }
    const clientIds = this.auth.roles()
      .filter(item => ['admin', 'mod'].includes(item.role_name))
      .map(item => item.client_id);
    return this.items().filter(item => clientIds.includes(item.id));
  });

  isActive = (client: Client) => computed(() => this.adminService.clientId() === client.id);
  activateClient = (client: Client) => {
    this.adminService.setClient(client);
  }
}
