import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export const confirmValidator = (control2: AbstractControl): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const value2 = control2.value;
    if (value !== value2) {
      return {invalidConfirm: true};
    }
    return null;
  }
};
