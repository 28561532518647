import {computed, inject, Injectable, signal, WritableSignal} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {CanActivateFn} from "@angular/router";
import {Project, projectFactory, ProjectListFilter} from "./types";
import {ListResponse, StatusResponse} from "../../types";
import {ApiService} from "../../services/api.service";
import {AppService} from "../../services/app.service";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private api = inject(ApiService);
  private app = inject(AppService);
  public currentItem: WritableSignal<Project> = signal(projectFactory());

  public filter = signal<ProjectListFilter>({
    search: '',
    page: 0,
    size: 50,
    orderBy: 'title',
    direction: 'asc',
    clientId: ''
  });

  filterSetSearch(search: string) {
    this.filter.update(filter => ({...filter, search}));
  }

  filterSetClientId(clientId: string) {
    this.filter.update(value => ({...value, clientId}));
  }

  private listResponse = signal<ListResponse<Project>>({
    items: [],
    total: 0
  });
  public items = computed(() => this.listResponse().items);
  public total = computed(() => this.listResponse().total);

  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter().search)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`)
      .set('client_id', `${this.filter().clientId}`)
      ;
  }

  async loadList() {
    const response = await this.api.fetchList<Project>('project', this.listParams);
    this.listResponse.set(response);
    return response;
  }

  async fetchItem(id: string){
    return this.api.fetchById<Project>('project', id);
  }

  async save(item: Project): Promise<Project> {
    return this.api.save<Project>('project', item);
  }

  async deleteById(id: string){
    return this.api.deleteById<Project>('project', id);
  }

  async deploy(id: string) {
    return this.api.post<StatusResponse>(`project-deploy`, {project_id: id});
  }
}

export const canEditProject: CanActivateFn = async (route, state) => {
  const service = inject(ProjectService);
  const projectId = `${route.paramMap.get('id')}`;

  if(projectId.length < 1) {
    console.log('canEditProject', 'failing reading id');
    service.currentItem.set(projectFactory());
    return true;
  }

  try {
    const item = await service.fetchItem(`${route.paramMap.get('id')}`);
    service.currentItem.set(item);
    return true;
  } catch {
    return false;
  }
};

export const canCreateProject: CanActivateFn = (route, state) => {
  inject(ProjectService).currentItem.set(projectFactory());
  return true;
};
