import {ChangeDetectionStrategy, Component, HostBinding, inject, input, output} from "@angular/core";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {NotificationService} from "../../services/notification.service";
import {PinboardEntry, PinboardEntryComment, pinboardEntryCommentFactory, pinboardEntryFactory} from "./types";
import {PinboardEntryCommentService} from "./pinboard-entry-comment.service";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatDivider} from "@angular/material/divider";
import {MatButton} from "@angular/material/button";
import {MatOption, MatSelect} from "@angular/material/select";
import {JsonPipe} from "@angular/common";

@Component({
  selector: 'pinboard-entry-comment-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    MatLabel,
    MatDivider,
    MatButton,
    MatSelect,
    MatOption,
    JsonPipe
  ],
  template: `
    <div class="component-title">Kommentare</div>
    <div class="form-row flex-row gap">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input [formControl]="authorNameControl" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input [formControl]="authorEmailControl" matInput />
      </mat-form-field>
    </div>
    <div class="mat-form-field-full-container">
      <mat-form-field appearance="outline">
        <mat-label>Artikel-Text</mat-label>
        <textarea [formControl]="contentControl" rows="4" matInput></textarea>
      </mat-form-field>
    </div>
    <div class="button-container">
      <button (click)="save()" mat-raised-button>Absenden</button>
    </div>
  `
})
export class PinboardEntryCommentFormComponent {
  @HostBinding('class.pinboard-entry-comment-form') classAdminPage = true;
  private note = inject(NotificationService);
  private service = inject(PinboardEntryCommentService);
  entry = input<PinboardEntry>(pinboardEntryFactory());
  saved = output<void>();
  authorNameControl = new FormControl('', [Validators.required]);
  authorEmailControl = new FormControl('', [Validators.required]);
  contentControl = new FormControl('', [Validators.required]);

  form =new FormGroup({
    authorName: this.authorNameControl,
    authorEmail: this.authorEmailControl,
    content: this.contentControl,
  });

  reset()  {
    this.authorNameControl.reset();
    this.authorEmailControl.reset();
    this.contentControl.reset();
  }

  save() {

    if(!this.form.valid) {
      console.log('form invalid');
      return;
    }

    const comment: PinboardEntryComment = {
      ...pinboardEntryCommentFactory(),
      client_id: this.entry().client_id,
      project_id: this.entry().project_id,
      pinboard_entry_id: this.entry().id,
      author_name: this.authorNameControl.value as string,
      author_email: this.authorEmailControl.value as string,
      content: this.contentControl.value as string,
    }

    console.log('save comment', comment);

    this.service.save(comment)
      .then(this.success)
      .catch(this.fail);
  }

  success = () => {
    this.note.note('Gespeichert!');
    this.reset();
    this.saved.emit();
  }

  fail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }
}
