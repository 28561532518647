import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {AuthRegisterService} from "../services/auth-register.service";

export const canRegisterConfirm: CanActivateFn =  async (route, state) => {
  const registerService = inject(AuthRegisterService);
  registerService.registerKey.set(`${route.paramMap.get('registerKey')}`);
  try {
    const response = await registerService.registerCheckKey({
      register_key: registerService.registerKey()
    });
    registerService.checkResponse.set(response);
  } catch (e) {
    console.log('canRegisterConfirm::checkResponse:', e);
  }
  return true;
}
