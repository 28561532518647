import {ChangeDetectionStrategy, Component, inject, OnInit} from "@angular/core";
import {ProjectService} from "./project.service";
import {
  MatCell,
  MatCellDef,
  MatColumnDef, MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {JsonPipe} from "@angular/common";
import {Project} from "./types";
import {PageEditorService} from "../page-editor/page-editor.service";
import {NavigationService} from "../../services/navigation.service";
import {AppService} from "../../services/app.service";
import {AdminService} from "../../admin/admin.service";

@Component({
  selector: 'app-admin-project-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTable,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatIcon,
    MatMiniFabButton,
    RouterLink,
    MatHeaderCellDef,
    JsonPipe,
    MatButton,
  ],
  template: `
    <table mat-table [dataSource]="items()">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Titel</th>
        <td mat-cell *matCellDef="let item"> {{ item.title }}</td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <div class="flex-row">
            <button mat-button [routerLink]="tagLink(item.id)()">
              <mat-icon>bookmark</mat-icon>
              TAGs
            </button>
            <button mat-button (click)="navigateToPageEditor(item)">
              <mat-icon>edit</mat-icon>
              Editor
            </button>
            <button mat-button [routerLink]="detailLink(item.id)()">
              <mat-icon>search</mat-icon>
              Details
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `,
})
export class AdminProjectListComponent {
  private service = inject(ProjectService);
  private nav = inject(NavigationService);
  private pageEditor = inject(PageEditorService);
  detailLink = (id: string) => this.nav.getSegments(`admin/project/${id}`);
  tagLink = (id: string) => this.nav.getSegments(`admin/project-tags/${id}`);
  // pageEditorLink$ = (id: string) => this.nav.getSegments(`admin/page-editor/${id}`);
  items = this.service.items;
  displayedColumns: string[] = ['title', 'id'];

  navigateToPageEditor(project: Project) {
    this.pageEditor.initProject(project);
    this.pageEditor.navigateToInitializedProject();
  }
}
