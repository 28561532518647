import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {SiteService} from "./site.service";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-site-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-row fields-container">
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Label</mat-label>
          <input matInput [formControl]="label" />
          @if (!label.valid) {
            <mat-error>Label mit nim 6 Zeichen benötigt!</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Titel</mat-label>
          <input matInput [formControl]="title" />
        </mat-form-field>
      </div>
    </div>


    <div class="flex flex-row overflow-clip button-container">
      <div class="flex-grow"></div>
      <div><button mat-raised-button
                   (click)="submit()"
                   color="primary">Speichern</button></div>
    </div>
  `
})
export class AdminSiteFormComponent {
  private snack = inject(MatSnackBar);
  private router = inject(Router);
  private service = inject(SiteService);
  item = this.service.currentItem$;
  public label = new FormControl(this.item().label,[
    Validators.required,
    Validators.minLength(6)
  ]);
  public title = new FormControl(this.item().title);
  private form = new FormGroup({
    label: this.label,
    title: this.title,
  });
  public submit() {
    if(!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.service
      .save({
        ...this.item(),
        label: this.label.value as string,
        title: this.title.value as string,
      })
      .then(this.success)
      .catch(this.fail);
  }

  private success = () => {
    this.snack.open('Speichern erfolgreich!', '', {duration: 2000});
    this.router.navigate(['/admin', 'sites']);
  }

  private fail = () => {
    this.snack.open('Speichern fehlgeschlagen!', '', {duration: 2000});
  }
}
