import {HasId, ListFilter, ModerationStatus} from "../../types";


export interface PinboardEntryListFilter extends ListFilter {
  clientId: string;
  projectId: string;
  moderationStatus: ModerationStatus | '';
  tagIds: string[];
}

export const pinboardEntryListFilterFactory = (): PinboardEntryListFilter => ({
  search: '',
  clientId: '',
  projectId: '',
  moderationStatus: '',
  page: 0,
  size: 50,
  orderBy: 'created',
  direction: 'desc',
  tagIds: []
});

export interface PinboardTagListFilter extends ListFilter {
  projectId: string;
  clientId: string;
}

export interface PinboardEntry extends HasId {
  id: string;
  created: string;
  modified: string;
  client_id: string;
  project_id: string;
  tag_ids: Array<string>;
  moderation_status: ModerationStatus;
  author_name: string;
  author_email: string;
  title: string;
  short_description: string;
  content: string;
}

export const pinboardEntryFactory = (): PinboardEntry => ({
  id: '',
  created: '',
  modified: '',
  client_id: '',
  project_id: '',
  tag_ids: [],
  moderation_status: 'pending',
  author_name: '',
  author_email: '',
  title: '',
  short_description: '',
  content: '',
});

export interface PinboardTag extends HasId {
  id: string;
  client_id: string;
  project_id: string;
  created: string;
  modified: string;
  name: string;
}

export const pinboardTagFactory = (): PinboardTag => ({
  id: '',
  client_id: '',
  project_id: '',
  created: '',
  modified: '',
  name: '',
});

export interface PinboardEntryComment  extends HasId {
  id: string;
  created: string;
  modified: string;
  client_id: string;
  project_id: string;
  pinboard_entry_id: string;
  moderation_status: ModerationStatus;
  author_name: string;
  author_email: string;
  content: string;
}

export const pinboardEntryCommentFactory = (): PinboardEntryComment => ({
  id: '',
  created: '',
  modified: '',
  client_id: '',
  project_id: '',
  pinboard_entry_id: '',
  moderation_status: 'pending',
  author_name: '',
  author_email: '',
  content: '',
});

export interface PinboardEntryCommentListFilter extends ListFilter {
  clientId: string;
  projectId: string;
  pinboardEntryId: string;
  moderationStatus: ModerationStatus | '';
}

export const pinboardEntryCommentListFilterFactory = (): PinboardEntryCommentListFilter => ({
  search: '',
  clientId: '',
  projectId: '',
  pinboardEntryId: '',
  moderationStatus: '',
  page: 0,
  size: 50,
  orderBy: 'created',
  direction: 'desc',
});


