import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, signal} from "@angular/core";
import {DynamicNavigationNode, dynamicNavigationNodeFactory} from "./types";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {PageEditorService} from "../page-editor/page-editor.service";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: "dynamic-navigation-node",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatIcon,
    RouterLink
  ],
  template: `
    @switch (type()) {
      @case ('link_intern') {
        <div class="link-item"><a [routerLink]="internRouterLink()">{{ label() }}</a></div>
      }
      @case ('link_extern') {
        <div class="link-item"><a href="#">{{ label() }}</a></div>
      }
      @case ('node') {
        <div class="link-menu">
          <div class="link-item">
            <a (click)="nope()" [matMenuTriggerFor]="menu">
              {{ label() }}
              <mat-icon>arrow_drop_down</mat-icon>
            </a>
          </div>
          <mat-menu #menu="matMenu">
            @for (child of node().nodes; track child.id) {
              <!--              <dynamic-navigation-node [node$]="child"/>-->
              <button mat-menu-item>{{ getLabel(child) }}</button>
            }
          </mat-menu>
        </div>
      }
    }
  `
})
export class DynamicNavigationNodeComponent {
  @HostBinding('class.dynamic-navigation-node') classAdminPage = true;
  private nav = inject(NavigationService);
  isDeployView = input(false);
  node = input<DynamicNavigationNode>(dynamicNavigationNodeFactory());
  pageEditorService = inject(PageEditorService);
  internRouterLink = computed<Array<string>>(() => {
      if (this.node().type === "link_intern") {
        if(this.isDeployView()) {

          // fix "/" at the beginning
          let name = this.node().name;
          if(name.length > 1 && name[0] === '/') {
            name = name.substring(1);
          }

          return this.nav.getSegments(name)();
        }

        return this.pageEditorService.getPageSegments(this.node().target_page_id)();
      }

      return [''];
  });

  type = computed(() => this.node().type);
  label = computed(() => (JSON.parse(this.node()?.label || '{}') as {de: string})?.de || '-?-')

  getLabel(node: DynamicNavigationNode): string {
    const nodeLabel = JSON.parse(node.label || '{}') as {de: string};
    return nodeLabel.de || '-?-';
  }
  nope = () =>  {
    return false;
  }
}
