{
  "total": 2,
  "items": [{
    "id": "comment-1",
    "content": "Lorem Ipsum 1",
    "created": "2024-02-01 09:34:12",
    "review_status": "pending"
  },{
    "id": "comment-2",
    "content": "Lorem Ipsum 2",
    "created": "2024-02-01 09:34:12",
    "review_status": "pending"
  }]
}
