import {ChangeDetectionStrategy, Component, HostBinding, inject} from '@angular/core';
import {SiteService} from "./site.service";
import {RouterLink} from "@angular/router";
import {AdminSiteListComponent} from "./admin-site-list.component";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NavigationService} from "../../services/navigation.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'app-admin-site-list-page',
  standalone: true,
  imports: [
    RouterLink,
    AdminPageHeaderComponent,
    AdminSiteListComponent,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <admin-page-header title="Seitenverwaltung" />

    <div class="toolbar">
      <div class="flex button-container">
        <button [routerLink]="link$('admin/site')()" mat-button>
          <mat-icon>add</mat-icon>
          Seite Erstellen
        </button>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="list-container">
      <app-site-list />
    </div>
  `
})
export class AdminSiteListPageComponent {
  @HostBinding('class.admin-page') classAdminPage = true;
  private service = inject(SiteService);
  private nav = inject(NavigationService);
  link$ = this.nav.getSegments;
  public search = new FormControl(
    this.service.filter$().search
  );
  constructor() {
    this.service.loadList();
  }

  reload = () => {
    this.service.filter$.update(
      value => ({...value, search: `${this.search.value}`})
    );
    this.service.loadList();
  }
}
