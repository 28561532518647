import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {VersionService} from "../services/version.service";
import {AdminPageHeaderComponent} from "./admin-page-header.component";

@Component({
  selector: 'app-admin-info-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    AdminPageHeaderComponent
  ],
  template: `
    <admin-page-header title="Info" />
    <div class="version-block">
      <span>Version</span>
      <span>FE: {{feVersion$ | async}}</span>
      <span>BE: {{beVersion$()}}</span>
    </div>
  `,
  styles: [`
    .version-block {
      padding-bottom: 1rem;

      span {
        display: block;
        padding: 0 8px;
      }

      span:first-child {
        font-weight: bold;
      }
    }
  `]
})
export class AdminInfoPageComponent implements OnInit {
  @HostBinding('class.admin-page') classAdminPage = true;
  private version = inject(VersionService);
  public feVersion$ = this.version.feVersion$;
  public beVersion$ = this.version.beVersion$;

  ngOnInit() {
    this.version.initBackendVersion();
  }
}
