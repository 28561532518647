import {HasId} from "../../types";

export interface User extends HasId {
  id: string;
  is_system_admin: boolean;
  mandant_roles: [];
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address_country: string;
  address_city: string;
  address_postal: string;
  address_street: string;
  password_tmp: string;
}
export const userFactory = (): User => ({
  id: '',
  is_system_admin: false,
  mandant_roles: [],
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  address_country: '',
  address_city: '',
  address_postal: '',
  address_street: '',
  password_tmp: '',
});

export interface UserRole {
  client_id: string;
  role_name: string;
  client_name: string;
}
