import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DomainService} from "./domain.service";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatPaginatorModule} from "@angular/material/paginator";
import {AdminUserListComponent} from "../user";
import {RouterLink} from "@angular/router";
import {AdminDomainListComponent} from "./admin-domain-list.component";
import {NavigationService} from "../../services/navigation.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'app-admin-domain-list-page',
  standalone: true,
  imports: [
    AdminPageHeaderComponent,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    AdminUserListComponent,
    RouterLink,
    ReactiveFormsModule,
    AdminDomainListComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <admin-page-header title="Domainverwaltung" />

    <div class="toolbar">
      <div class="flex button-container">
        <button [routerLink]="link$('admin/domain')()" mat-button>
          <mat-icon>add</mat-icon>
          Domain Erstellen
        </button>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div class="list-container">
      <app-domain-list/>
    </div>
  `
})
export class AdminDomainListPageComponent implements OnInit {
  @HostBinding('class.admin-page') classAdminPage = true;
  private nav = inject(NavigationService);
  link$ = this.nav.getSegments;
  private service = inject(DomainService);
  public search = new FormControl(this.service.filter$().search);

  ngOnInit() {
    this.service.loadList();
  }

  reload = () => {
    this.service.filter$.update(
      value => ({...value, search: `${this.search.value}`})
    );
    this.service.loadList();
  }
}
