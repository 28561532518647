import {ChangeDetectionStrategy, Component, computed, HostBinding, inject} from "@angular/core";
import {PageEditorService} from "../page-editor/page-editor.service";
import {MatDivider} from "@angular/material/divider";
import {JsonPipe} from "@angular/common";
import {DynamicNavigationNodeEditorComponent} from "./dynamic-navigation-node-editor.component";

@Component({
  selector: 'dynamic-navigation-editor',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDivider,
    JsonPipe,
    DynamicNavigationNodeEditorComponent
  ],
  template: `
    @for (node of nodes(); track node) {
      <dynamic-navigation-node-editor [node]="node" />
    }

    <mat-divider />
    <p>Neue Unterseite hinzufügen</p>
    <dynamic-navigation-node-editor />
  `
})
export class DynamicNavigationEditorComponent {
  @HostBinding('class.dynamic-navigation-editor') dssClass = true;
  pageEditorService = inject(PageEditorService);
  nodes = computed(() => this.pageEditorService.config().navigationNodes);
}
