import {Component, HostBinding, input, signal} from "@angular/core";
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'dynamic-hero',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  template: `
    <img [src]="imgSrc()" alt="Hero Image" />
  `
})
export class DynamicHeroComponent {
  @HostBinding('class.dynamic-hero') classDynamicHero = true;
  imgSrc = signal('/img/metzingen.jpg');
  text = input<string>('');
}

