import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {SiteService} from "./site.service";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-site-list',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <table mat-table [dataSource]="items$()">
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef> Label </th>
        <td mat-cell *matCellDef="let item"> {{item.label}} </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell class="actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <button mat-button
                  color="primary"
                  [routerLink]="detailLink$(item.id)()">
            <mat-icon>search</mat-icon>
            Details
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  `
})
export class AdminSiteListComponent {
  private service = inject(SiteService);
  private nav = inject(NavigationService);
  detailLink$ = (id: string) => this.nav.getSegments(`admin/site/${id}`);
  public items$ = this.service.items$;
  public displayedColumns: string[] = ['label', 'id'];
}
