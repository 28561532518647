import {HasId, ListFilter} from "../../types";

export interface AssetListFilter extends ListFilter {
  clientId: string;
}

export interface Asset extends HasId {
  id: string;
  name: string;
  size: string;
  content_type: string;
  created: string;
  modified: string;
}

export const assetFactory = (): Asset => ({
  id: '',
  name: '',
  size: '',
  content_type: '',
  created: '',
  modified: '',
});
