import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {ProjectService} from "../../project/project.service";
import {AdminService} from "../../../admin/admin.service";
import {PinboardEntryService} from "../pinboard-entry.service";

export const canAdminPinboardEntryListPage: CanActivateFn =  async (route, state) => {
  const service = inject(PinboardEntryService);
  const projectService = inject(ProjectService);
  const adminService = inject(AdminService);
  const adminClientId = adminService.clientId();
  const projectClientId = projectService.filter().clientId;
  const clientId = service.filter().clientId;

  if(clientId === adminClientId && projectClientId && service.filter().projectId.length > 0) {
    // console.log('entries: projectId set', service.filter().projectId);
    return true;
  }

  service.filterSetClientId(adminClientId);
  projectService.filterSetClientId(adminService.clientId());

  await projectService.loadList();
  const projects = projectService.items();
  if (projects.length > 0) {
    service.filterSetClientId(adminService.clientId());
    service.filterSetProjectId(projects[0].id);
  }
  return true;
}
