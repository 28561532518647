import {ChangeDetectionStrategy, Component, computed, HostBinding, input, Input, signal} from "@angular/core";
import {Asset, assetFactory} from "./types";

@Component({
  selector: 'asset-display, [asset-display]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    AssetDisplayComponent
    <div>hasAsset {{hasAsset()}}</div>
  `
})
export class AssetDisplayComponent {
  @HostBinding('class.asset-display') dssClass = true;
  asset= input(assetFactory());
  missingText = input('Empty');
  hasAsset = computed(() => this.asset().id.length > 0);
}
