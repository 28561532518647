import {
  ChangeDetectionStrategy,
  Component, computed,
  HostBinding, inject,
  input,
  output,
  signal,
  ViewChild
} from "@angular/core";
import {MapComponent} from "./map.component";
import {MatButton, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatToolbar} from "@angular/material/toolbar";
import {RichMapCommentFormComponent} from "./rich-map-comment-form.component";
import {ComponentConfig, componentConfigFactory} from "../project";
import {JsonPipe} from "@angular/common";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'rich-map-container',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MapComponent,
    MatIconButton,
    MatIcon,
    MatButton,
    MatMiniFabButton,
    MatToolbar,
    RichMapCommentFormComponent,
    JsonPipe
  ],
  template: `
    <mat-toolbar>
      @if (isEditorMode()) {
        <button (click)="prepareDefineCenter()" mat-button>Startpunkt auswählen</button>
      } @else {
        <button (click)="toggleOverlay()" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
        <button (click)="toggleAddMarkerState()" mat-button>Marker hinzufügen</button>

      }
    </mat-toolbar>
    <div class="rich-map-map-container">
      <pa-map #map (mapClick)="mapClicked($event)" [center]="center()"/>
<!--      <pre>center: {{center() | json}}</pre>-->
      <div class="rich-map-map-overlay flex-row" [class.hide]="overlayHidden()">
        <rich-map-comment-form/>
      </div>
    </div>
  `,
})
export class RichMapContainerComponent {
  @HostBinding('class.rich-map') classRichMap = true;
  isDeployView = input(false);
  isEditorMode = input(false);
  component = input<ComponentConfig>(componentConfigFactory());
  onSelectCenter = output<{latlng: L.LatLng, zoom: number}>();
  @ViewChild(MapComponent)
  map: MapComponent | undefined;
  private note = inject(NotificationService);
  addMarkerState = signal(false);
  flagSelectCenter = signal(false);
  overlayHidden = signal(true);

  center = computed(() => {
    const config = this.component().config;

    if ( Object.hasOwn(config, 'center')) {
      return config['center'];
    }

    return {
      // latlng: [52.5200, 13.4050], // Berlin
      // latlng: [48.73336, 9.31976], // Esslingen
      latlng: {lat: 48.73336, lng: 9.31976}, // Esslingen
      zoom: 12
    };
  });



  toggleOverlay() {
    this.note.note('under construction...');
    // this.overlayHidden.update(value => !value);
    // if(this.overlayHidden()) {
    //   this.addMarkerState.set(false);
    // }
  }
  toggleAddMarkerState() {
    this.note.note('under construction...');
    // this.addMarkerState.update(value => !value);
  }

  mapClicked(event: {latlng: L.LatLng, zoom: number}) {
    if(this.addMarkerState()) {
      this.overlayHidden.set(false);
      this.map!.addMarker(event.latlng);
    }

    if(this.flagSelectCenter()) {
      // console.log('flagSelectCenter', {event});
      this.flagSelectCenter.set(false);
      this.onSelectCenter.emit(event);
    }
  }

  prepareDefineCenter() {
    console.log('define center clicked');
    this.flagSelectCenter.set(true);
  }
}
