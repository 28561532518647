import {inject, Injectable} from "@angular/core";
import {CanActivateFn} from "@angular/router";
import {PageEditorService} from "../../page-editor.service";

@Injectable({providedIn: 'root'})
export class ArticleEditorService {

}

export const canEditArticle: CanActivateFn = async (route, state) => {
  const pageEditorService = inject(PageEditorService);
  const components = pageEditorService.page().components;
  const componentId = `${route.paramMap.get('component_id')}`;
  const component = components.find(component => component.id === componentId);
  if(component !== undefined) {
    if(component.type !== 'article') {
      console.log('invalid component type', component);
      return false;
    }
    pageEditorService.component.set(component);
    return true;
  } else {
    console.log('component id not found');
    return false
  }
}
