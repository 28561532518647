import {computed, inject, Injectable, signal} from "@angular/core";
import {ApiService} from "../../services/api.service";
import {AppService} from "../../services/app.service";
import {listResponseFactory} from "../../types";
import {HttpParams} from "@angular/common/http";
import {MapTag, MapTagListFilter, mapTagListFilterFactory} from "./types";

@Injectable({providedIn: 'root'})
export class MapTagService {
  private api = inject(ApiService);
  public filter = signal<MapTagListFilter>(mapTagListFilterFactory());
  private listResponse = signal(listResponseFactory<MapTag>());
  public items = computed(() => this.listResponse().items);
  public total = computed(() => this.listResponse().total);

  filterSetSearch(search: string) {
    this.filter.update(filter => ({...filter, search}));
  }

  filterSetClientId(clientId: string) {
    this.filter.update(value => ({...value, clientId}));
  }

  filterSetProjectId(projectId: string) {
    this.filter.update(value => ({...value, projectId}));
  }

  private get listParams(): HttpParams {
    return new HttpParams()
      .set('search', this.filter().search)
      .set('client_id', `${this.filter().clientId}`)
      .set('project_id', `${this.filter().projectId}`)
      .set('page', `${this.filter().page}`)
      .set('size', `${this.filter().size}`)
      .set('order_by', `${this.filter().orderBy}`)
      .set('direction', `${this.filter().direction}`)
      ;
  }

  async loadList() {
    const response = await this.api.fetchList<MapTag>('maptag', this.listParams);
    this.listResponse.set(response);
    return response;
  }

  async save(item: MapTag) {
    return this.api.save<MapTag>('maptag', item);
  }
}
