import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, output} from "@angular/core";
import {AssetService} from "./asset.service";
import {Asset, assetFactory} from "./types";
import {MatButton, MatIconAnchor, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {NotificationService} from "../../services/notification.service";
@Component({
  selector: 'admin-asset-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButton,
    MatMiniFabButton,
    MatIcon,
    MatIconButton,
    MatIconAnchor,
  ],
  template: `
    <div class="asset-card-thumb-container">
      @if (isImage()) {
        <img [src]="src" [alt]="asset().name"/>
      } @else if (isPdf()) {
        <div class="asset-as-icon">
          <mat-icon>picture_as_pdf</mat-icon>
        </div>
      } @else {
        <div class="asset-as-icon">
          <mat-icon>file_copy</mat-icon>
        </div>
      }
    </div>
    <div class="image-card-footer">
      <div class="file-name">{{ asset().name }}</div>
    </div>
    <div class="button-container">
      <!--      <button (click)="select()" mat-icon-button><mat-icon>check_box_outline_blank</mat-icon></button>-->
      @if (calculatedSelectable()) {
        <button (click)="onSelect()" mat-button>auswählen</button>
      }
      <button (click)="onDelete()" mat-button>löschen</button>
      <div class="flex-spacer"></div>
      <div>
        <a [href]="src" target="_blank" mat-icon-button><mat-icon>download</mat-icon></a>
      </div>
    </div>
<!--    <div>-->
<!--      <div>ContentType: {{asset().content_type}}</div>-->
<!--      <div>selectType: {{selectType()}}</div>-->
<!--      <div>isImage: {{isImage()}}</div>-->
<!--    </div>-->
  `
})
export class AdminAssetCardComponent {
  @HostBinding('class.admin-asset-card') cssClass = true;
  asset= input(assetFactory());
  selectable = input(false);
  selectType = input<'all' | 'images'>('all')
  selected = output<Asset>();
  delete = output<Asset>();
  private service = inject(AssetService);
  private note = inject(NotificationService);
  isImage = computed(() => this.service.assetTypesImage.some(type => this.asset().content_type === type));
  isPdf = computed(() => this.asset().content_type === this.service.assetTypesPdf);
  calculatedSelectable = computed(() => {
    if (!this.selectable()) {
      return false;
    }

    if(this.selectType() === 'all') {
      return true;
    }

    if(this.selectType() === 'images') {
      const contentType = this.asset().content_type;
      const types = this.service.assetTypesImage;
      return types.some(type => type === contentType);
    }

    return true;
  });

  get src() {
    return this.service.getDisplayUrl(this.asset() as Asset);
  };

  onSelect() {
    this.selected.emit(this.asset());
  }

  onDelete() {
    this.note.confirm('Asset löschen?', () => {
      this.delete.emit(this.asset());
    })
  }
}
