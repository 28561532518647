import {computed, inject, Inject, Injectable, signal} from "@angular/core";
import {ListFilter, listFilterFactory, ListResponse} from "../../types";
import {HttpClient, HttpParams} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {mockUserCommentListResponse} from "./mock";
import {UserComment, userCommentFactory} from "./types";
import {CanActivateFn} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Injectable({providedIn: "root"})
export class UserCommentService {
  private http = inject(HttpClient);
  public filter$ = signal<ListFilter>(listFilterFactory());
  private listParams$ = computed(() => new HttpParams()
    .set('search', this.filter$().search)
    .set('page', `${this.filter$().page}`)
    .set('size', `${this.filter$().size}`));
  private listResponse$ = signal<ListResponse<UserComment>>({
    items: [],
    total: 0
  });
  public items$ = computed(() => this.listResponse$().items);
  public total$ = computed(() => this.listResponse$().total);
  public currentItem$ = signal<UserComment>(userCommentFactory());

  loadList() {
    if (environment.noBackend) {
      this.listResponse$.set(mockUserCommentListResponse);
      return;
    }

    firstValueFrom(this.http.get(''))
      .then(value => this.listResponse$.set(value as ListResponse<UserComment>))
      .catch(error => console.error('CommentService::loadList()', error));
  }

  async loadItem(id: string): Promise<UserComment> {
    if (environment.noBackend) {
      return Promise.resolve<UserComment>(
        this.items$().find(item => item.id === id) || userCommentFactory()
      );
    }
    return firstValueFrom(this.http.get<UserComment>(`/api/user-comment/${id}`));
  }
}

export const canEditUserComment: CanActivateFn = async (route, state) => {
  const service = inject(UserCommentService);
  try {
    const item = await service.loadItem(`${route.paramMap.get('id')}`);
    service.currentItem$.set(item);
  } catch {
    service.currentItem$.set(userCommentFactory());
  }
  return true;
}

export const canCreateUserComment: CanActivateFn = (route, state) => {
  inject(UserCommentService).currentItem$.set(userCommentFactory());
  return true;
}
