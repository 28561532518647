import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  input,
  OnInit,
  signal
} from "@angular/core";
import {PageEditorService} from "../page-editor/page-editor.service";
import {MatDivider} from "@angular/material/divider";
import {JsonPipe} from "@angular/common";
import {DynamicNavigationNode, dynamicNavigationNodeFactory} from "./types";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {I18nTextInputComponent} from "../i18n/i18n-text-input.component";
import {MatButton, MatIconButton} from "@angular/material/button";
import {urlSegmentValidator, uuid} from "../../functions";
import {pageConfigFactory} from "../project";
import {MatIcon} from "@angular/material/icon";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'dynamic-navigation-node-editor',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDivider,
    JsonPipe,
    MatFormField,
    FormsModule,
    MatInput,
    MatLabel,
    MatError,
    ReactiveFormsModule,
    I18nTextInputComponent,
    MatButton,
    MatIcon,
    MatIconButton
  ],
  template: `
    <div class="form-row">
      <div>
        @if (isNew()) {
          <mat-form-field appearance="outline">
            <mat-label>Pfad</mat-label>
            <input matInput [formControl]="nameControl"/>
            <mat-error>
              @if (nameControl.hasError('required')) {
                Feld kann nicht leer sein.
              } @else {
                Nur: a-z, 0-9 oder "-" dazwischen
              }
            </mat-error>
          </mat-form-field>
        } @else {
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput [value]="name()" [disabled]="true"/>
          </mat-form-field>
        }
      </div>
      <div>
        <i18n-text-input [(value)]="label"
                         label="Anzeige"
                         [required]="true"
                         errorMessage="Wird benötigt"/>
      </div>
      <div>
        @if (isNew()) {
          <button (click)="onSave()" mat-button>Hinzufügen</button>
        } @else {
          @if (canDelete()) {
            <button (click)="onDelete()" mat-button>Löschen</button>
          }
          <button (click)="onSave()" mat-button>Speichern</button>

          @if (!isHome()) {
            <button (click)="moveNodeUp()" mat-icon-button><mat-icon>arrow_upward</mat-icon></button>
            <button (click)="moveNodeDown()" mat-icon-button><mat-icon>arrow_downward</mat-icon></button>
          }
        }
      </div>
    </div>
  `
})
export class DynamicNavigationNodeEditorComponent implements OnInit {
  @HostBinding('class.dynamic-navigation-node-editor') dssClass = true;
  node = input<DynamicNavigationNode>(dynamicNavigationNodeFactory());
  pageEditorService = inject(PageEditorService);
  note = inject(NotificationService);
  isNew = computed(() => this.node().id.length < 1);
  isHome = computed(() => !this.isNew() && this.node().name.length < 1);
  canDelete = computed(() => !this.isNew() && !this.isHome());
  name = computed(() => this.node().name.length > 0 ? this.node().name : '/');
  nameControl = new FormControl('', [Validators.required, urlSegmentValidator]);
  label = signal('');

  onDelete() {
    this.note.confirm('Page löschen?', () => {
      this.pageEditorService.deleteNode(this.node());
    });
  }

  onSave() {
    const node = {
      ...this.node(),
      label: this.label()
    };

    if(this.isNew()) {

      node.id = uuid();
      node.name = `${this.nameControl.value}`;

      const page = pageConfigFactory();
      page.id = uuid();
      node.target_page_id = page.id

      this.pageEditorService.addPage(page);
      this.pageEditorService.addNode(node);

      // reset
      this.nameControl.setValue('', {emitEvent: false});
      this.nameControl.markAsUntouched();
      this.label.set('');

    } else {
      this.pageEditorService.updateNode(node);
    }
  }

  moveNodeUp() {
    this.pageEditorService.moveNavigationNodeUp(this.node());
  }

  moveNodeDown() {
    this.pageEditorService.moveNavigationNodeDown(this.node());

  }

  ngOnInit() {
    this.label.set(this.node().label || JSON.stringify({}));
    this.nameControl.setValue(this.node().name, {emitEvent: false});
  }
}
