import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {DomainService} from "./domain.service";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-domain-detail',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-row fields-container">
      <div class="flex-1 field-container">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput [formControl]="name"/>
          @if (!name.valid) {
            <mat-error>Name mit nim 6 Zeichen benötigt!</mat-error>
          }
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-row overflow-clip button-container">
      <div class="flex-grow"></div>
      <div>
        <button mat-raised-button
                (click)="submit()"
                color="primary">Speichern
        </button>
      </div>
    </div>
  `
})
export class AdminDomainDetailComponent {
  private nav = inject(NavigationService);
  link$ = this.nav.getSegments;
  private snack = inject(MatSnackBar);
  private router = inject(Router);
  private service = inject(DomainService);
  item$ = this.service.currentItem$;
  public name = new FormControl(this.item$().name,[
    Validators.required,
    Validators.minLength(6)
  ]);
  private form = new FormGroup({name: this.name});
  public submit() {
    if(!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.service
      .save({ ...this.item$(), name: this.name.value as string})
      .then(this.success)
      .catch(this.fail);
  }

  private success = () => {
    this.snack.open('Speichern erfolgreich!', '', {duration: 2000});
    this.nav.navigate('admin/domains');
  }

  private fail = () => {
    this.snack.open('Speichern fehlgeschlagen!', '', {duration: 2000});
  }
}
