import {inject, Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../components/confirm-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private snack = inject(MatSnackBar);
  private dialog = inject(MatDialog);

  note = (message: string) => {
    this.snack.open(
      message,
      '',
      {duration: 2250}
    );
  }

  confirm = (message: string, confirmHandler: () => void) => {
    const ref = this.dialog.open(
      ConfirmDialogComponent, {
      data: {message}
    });

    const sub = ref.afterClosed().subscribe(result => {
      if(result) {
        confirmHandler();
      }
      sub.unsubscribe();
    });
  }
}
