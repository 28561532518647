import {inject, Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, startWith} from "rxjs";
import {environment} from "../../../environments/environment";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private api = inject(ApiService);
  private httpClient = inject(HttpClient);
  public feVersion$ = this.httpClient
    .get<{ version: string }>('/version.json')
    .pipe(map(response => response.version), startWith('loading...'));
  public beVersion$ = signal('loading...');

  initBackendVersion() {
    this.api.fetch<{version: string}>('version')
      .then(response =>this.beVersion$.set(response.version))
  }
}
