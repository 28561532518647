import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from "@angular/core";
import {DynamicSiteHeaderComponent} from "../navigation/dynamic-site-header.component";
import {PinboardEntryDisplayComponent} from "./pinboard-entry-display.component";
import {PageEditorService} from "../page-editor/page-editor.service";
import {PinboardEntryService} from "./pinboard-entry.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {PinboardEntryCommentFormComponent} from "./pinboard-entry-comment-form.component";
import {PinboardEntryCommentService} from "./pinboard-entry-comment.service";
import {JsonPipe} from "@angular/common";
import {PinboardEntryCommentDisplayComponent} from "./pinboard-entry-comment-display.component";

@Component({
  selector: 'pinboard-entry-display-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DynamicSiteHeaderComponent,
    PinboardEntryDisplayComponent,
    MatButton,
    MatIcon,
    PinboardEntryCommentFormComponent,
    JsonPipe,
    PinboardEntryCommentDisplayComponent
  ],
  template: `
    <dynamic-site-header />
    <div class="page-content-container">
      <div class="toolbar">
        <div class="button-container">
          <button (click)="back()" mat-button>
            <mat-icon>arrow_back</mat-icon>
            Zurück
          </button>
        </div>
      </div>
    </div>

    <div class="page-content-container">
      <pinboard-entry-display [entry]="entry()" />
    </div>

    <div class="page-content-container">
      <pinboard-entry-comment-form [entry]="entry()" (saved)="afterSave()" />
    </div>
    <div class="page-content-container flex-column gap" style="padding-top: 2rem;">
      @for (comment of comments(); track comment) {
        <pinboard-entry-comment-display [comment]="comment" />
      }

    </div>

<!--    <pre>comments {{comments() | json}}</pre>-->
  `
})
export class PinboardEntryDisplayPageComponent implements OnInit {
  @HostBinding('class.pinboard-entry-display-page') classAdminPage = true;
  private pageEditorService = inject(PageEditorService);
  private service = inject(PinboardEntryService);
  private commentService = inject(PinboardEntryCommentService);
  entry = this.service.currentItem;
  comments = this.commentService.items;

  back() {
    this.pageEditorService.navigateToInitializedProject();
  }

  afterSave() {
    this.commentService.loadList();
  }

  ngOnInit() {
    this.commentService.filterSetPinboardEntryId(this.entry().id);
    this.commentService.filterSetClientId(this.entry().client_id);
    this.commentService.filterSetProjectId(this.entry().project_id);
    this.commentService.loadList();
  }
}
