
export interface UserComment {
  id: string;
  content: string;
  created: string;
  review_status: string | 'pending'| 'approved'| 'rejected';
}

export const userCommentFactory = (): UserComment => ({
  id: '',
  content: '',
  created: '',
  review_status: 'pending',
});
