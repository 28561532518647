import {ChangeDetectionStrategy, Component, HostBinding, inject} from '@angular/core';
import {SiteService} from "./site.service";
import {AdminSiteFormComponent} from "./admin-site-form.component";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {NavigationService} from "../../services/navigation.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'app-admin-site-detail-page',
  standalone: true,
  imports: [
    AdminPageHeaderComponent,
    AdminSiteFormComponent,
    MatButtonModule,
    MatIconModule,
    RouterLink
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <admin-page-header [title]="title" />

    <div class="toolbar">
      <div class="button-container">
        <button [routerLink]="link$('admin/sites')()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste
        </button>
      </div>
    </div>

    <div class="form-container">
      <app-site-form />
    </div>
  `
})
export class AdminSiteDetailPageComponent {
  @HostBinding('class.admin-page') classAdminPage = true;
  private nav = inject(NavigationService);
  link$ = this.nav.getSegments;
  private service = inject(SiteService);
  item$ = this.service.currentItem$;
  get title(): string {
    return this.item$().id.length > 0
      ? 'Site bearbeiten' : 'Site erstellen'
  }
}
