import {ChangeDetectionStrategy, Component, HostBinding, input} from "@angular/core";
import {DynamicNavigationComponent} from "./dynamic-navigation.component";
import {AuthMenuComponent} from "../../deploy/auth-menu.component";

@Component({
  selector: 'dynamic-site-header',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DynamicNavigationComponent,
    AuthMenuComponent
  ],
  template: `
    <dynamic-navigation [isDeployView]="isDeployView()"/>
    <auth-menu />
  `
})
export class DynamicSiteHeaderComponent {
  @HostBinding('class.dynamic-site-header') classAdminPage = true;
  isDeployView = input(false);
}
