import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, signal} from "@angular/core";
import {DeployService} from "./deploy.service";
import {HeroTeaserComponent} from "../features/page-editor/components/hero-teaser/hero-teaser.component";
import {RichMapContainerComponent} from "../features/map/rich-map-container.component";
import {PinboardComponent} from "../features/pinboard/pinboard.component";
import {PinboardEntryFormComponent} from "../features/pinboard/pinboard-entry-form.component";
import {ArticleComponent} from "../features/page-editor/components/article/article.component";

@Component({
  selector: 'project-deploy-view-default-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HeroTeaserComponent,
    RichMapContainerComponent,
    PinboardComponent,
    PinboardEntryFormComponent,
    ArticleComponent
  ],
  template: `
    @for (component of components(); track component) {
      @switch (component.type) {
        @case ('hero-teaser') {
          <hero-teaser [component]="component"
                       [isDeployView]="isDeployView()"/>
        }
        @case ('rich-map') {
          <div class="page-content-container">
            <rich-map-container [component]="component"
                                [isDeployView]="isDeployView()"/>
          </div>
        }
        @case ('map') {
          <div class="page-content-container">
            <rich-map-container [component]="component"
                                [isDeployView]="isDeployView()"/>
          </div>
        }
        @case ('pinboard') {
          <div class="page-content-container">
            <pinboard [component]="component"
                      [isDeployView]="isDeployView()"
                      [clientId]="clientId()"
                      [projectId]="projectId()"/>
            <pinboard-entry-form [pinboardComponent]="component"
                                 [isDeployView]="isDeployView()"
                                 [clientId]="clientId()"
                                 [projectId]="projectId()"/>
          </div>
        }
        @case ('article') {
          <div class="page-content-container">
            <article-component [component]="component"
                               [isDeployView]="isDeployView()"/>
          </div>
        }
      }
    }
  `
})
export class DeployDefaultPageComponent {
  @HostBinding('class.project-deploy-view-default-page') dssClass = true;
  isDeployView = signal(true);
  deployService = inject(DeployService);
  clientId = this.deployService.clientId;
  projectId = this.deployService.projectId;
  page = this.deployService.page;
  components = computed(() => this.page().components);
}
