import {HasId} from "../types";

export * from './uuid';
export * from './is-valid-domain';
export * from './domain-validator';
export * from './is-valid-page-path';
export * from './page-path-validator';
export * from './is-valid-url-segment';
export * from './url-segment-validator';
export * from './is-valid-password';
export * from './password-validator';
export * from './confirm-validator';

export const entityArrayShiftItemUp = <T extends HasId>(arr: Array<T>, item: T) => {
  const index = arr.findIndex(iItem => iItem.id === item.id);
  if (index < 1) {
    return;
  }
  arrayShiftItem(arr, index, index - 1);
}

export const entityArrayShiftItemDown = <T extends HasId>(arr: Array<T>, item: T) => {
  const index = arr.findIndex(iItem => iItem.id === item.id);
  if (index >= (arr.length - 1)) {
    return;
  }
  arrayShiftItem(arr, index, index + 1);
}

export const arrayShiftItem = (arr: Array<any>, fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}
