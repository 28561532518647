import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {MapEntryService} from "./map-entry.service";
import {MapTagService} from "./map-tag.servie";
import {mapEntryFactory} from "./types";
import {ProjectService} from "../project/project.service";
import {PinboardEntryService} from "../pinboard/pinboard-entry.service";
import {AdminService} from "../../admin/admin.service";

export const canReadMapEntry: CanActivateFn = async (route, state) => {
  const entryService = inject(MapEntryService);
  const tagService = inject(MapTagService);
  const entryId = `${route.paramMap.get('entry_id')}`;

  if(entryId.length < 1) {
    console.log('failing reading entry_id');
    return false;
  }

  try {
    const entry = await entryService.fetchItem(entryId);

    tagService.filter.update(filter => ({...filter, projectId: entry.project_id}));
    await tagService.loadList();
    entryService.currentItem.set(entry);
  } catch (e) {
    console.log('failing fetching entry');
    return false;
  }

  return true;
};

export const canEditMapEntry: CanActivateFn =  async (route, state) => {
  const entryService = inject(MapEntryService);
  const tagService = inject(MapTagService);

  try {
    const entryId = `${route.paramMap.get('id')}`;
    const entry = await entryService.fetchItem(entryId);
    if (!entry) {
      entryService.currentItem.set(mapEntryFactory());
      return true;
    }
    tagService.filter.update(filter => ({...filter, projectId: entry.project_id}));

    await tagService.loadList();
    entryService.currentItem.set(entry);
  } catch (e) {
    entryService.currentItem.set(mapEntryFactory());
  }
  return true;
}

export const canActivateMapEntryList: CanActivateFn =  async (route, state) => {
  const service = inject(MapEntryService);
  const projectService = inject(ProjectService);
  const adminService = inject(AdminService);

  if(service.filter().projectId.length > 0) {
    // console.log('entries: projectId set', service.filter().projectId);
    return true;
  }
  projectService.filterSetClientId(adminService.clientId());
  await projectService.loadList();
  const projects = projectService.items();
  if (projects.length > 0) {
    service.filterSetClientId(adminService.clientId());
    service.filterSetProjectId(projects[0].id);
  }
  return true;
}


