import {inject, Injectable, signal} from "@angular/core";
import {ApiService} from "../../../services/api.service";
import {CheckResponse} from "../../../types";
import {
  ResetPasswordCheckRequest,
  ResetPasswordFinalizeRequest, ResetPasswordRequest
} from "../types";

@Injectable({providedIn: 'root'})
export class AuthPasswordResetService {
  private api = inject(ApiService);
  resetKey = signal('');
  checkResponse = signal<CheckResponse>({success: false});

  passwordReset(data: ResetPasswordRequest) {
    return this.api.post<CheckResponse>('user-password-reset', data);
  }

  passwordResetCheckKey(data: ResetPasswordCheckRequest) {
    return this.api.post<CheckResponse>('user-password-reset-check', data);
  }

  passwordResetFinalize(data: ResetPasswordFinalizeRequest) {
    return this.api.post<CheckResponse>('user-password-reset-finalize', data);
  }
}
