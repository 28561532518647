import {ChangeDetectionStrategy, Component, EventEmitter, input, Input, OnInit, Output} from "@angular/core";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatLabel} from "@angular/material/select";
import {MatButton} from "@angular/material/button";
import {JsonPipe} from "@angular/common";

@Component({
  selector: 'app-dynamic-headline-form,[app-dynamic-headline-form]',
  standalone: true,
  template: `
    <div>
      <mat-form-field>
        <mat-label>Headline</mat-label>
        <input matInput [formControl]="contentControl" />
      </mat-form-field>
      <button (click)="save()" mat-button>apply</button>
    </div>
    <div>
      <pre>json: {{component | json}}</pre>
    </div>
  `,
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatButton,
    JsonPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicHeadlineFormComponent implements OnInit{
  @Input() component: { content:string } = {
    content: ''
  };
  @Output() update = new EventEmitter();
  contentControl = new FormControl('');

  save() {
    this.update.emit({
      ...this.component,
      content: this.contentControl.value
    });
  }

  ngOnInit() {
    this.contentControl.setValue(this.component.content, {emitEvent: false});
  }
}
