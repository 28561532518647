import {ChangeDetectionStrategy, Component, computed, inject, OnInit, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterOutlet} from '@angular/router';
import {AppService} from "./lib/services/app.service";

@Component({
  selector: 'app-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterOutlet],
  template: `
    @if (isBrowser()) {
      <router-outlet/>
    }
  `,
})
export class AppComponent {
  private app = inject(AppService);
  isBrowser = this.app.isBrowser;
}
