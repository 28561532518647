import {ChangeDetectionStrategy, Component, HostBinding, inject} from "@angular/core";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {PageEditorService} from "../../page-editor.service";
import {ArticleEditorComponent} from "./article-editor.component";
import {AdminPageHeaderComponent} from "../../../../admin/admin-page-header.component";

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    MatButton,
    MatIcon,
    ArticleEditorComponent
  ],
  template: `
    <admin-page-header title="Artikel bearbeiten" />
    <div class="toolbar">
      <div class="button-container">
        <button (click)="navigateToPageEditor()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zurück
        </button>
      </div>
    </div>

    <div class="form-container">
      <article-editor />
    </div>
  `
})
export class ArticleEditorPageComponent {
  @HostBinding('class.admin-page') dssClass = true;
  pageEditorService = inject(PageEditorService);

  navigateToPageEditor() {
    this.pageEditorService.navigateToInitializedProject();
  }
}
