import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit, output, signal} from "@angular/core";
import {ProjectService} from "./project.service";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {Project} from "./types";
import {domainValidator} from "../../functions";
import {NotificationService} from "../../services/notification.service";
import {AuthService} from "../auth/services/auth.service";
import {AdminService} from "../../admin/admin.service";

@Component({
  selector: 'admin-project-detail-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule
  ],
  template: `
    <div class="form-container">
      <mat-form-field appearance="outline">
        <mat-label>Domain</mat-label>
        <input matInput [formControl]="domainControl"/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Titel</mat-label>
        <input matInput [formControl]="titleControl"/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Beschreibung</mat-label>
        <textarea [formControl]="descriptionControl"
                  rows="5"
                  matInput></textarea>
      </mat-form-field>
      <div>
        <button mat-raised-button (click)="save()">Save</button>
        @if (!isNew()) {
          <button mat-raised-button (click)="delete()">Löschen</button>

        }
      </div>
    </div>
  `,
})
export class AdminProjectDetailFormComponent implements OnInit {
  @HostBinding('class.project-detail-form') cssClass = true;
  deleted = output<Project>();
  saved = output<Project>();
  private service = inject(ProjectService);
  private note = inject(NotificationService);
  private auth = inject(AuthService);
  private adminService = inject(AdminService);

  item = this.service.currentItem;
  isNew = computed(() => this.item().id.length < 1);
  domainControl = new FormControl('', [domainValidator]);
  titleControl = new FormControl('');
  descriptionControl = new FormControl('');

  ngOnInit() {
    this.domainControl.setValue(this.item().domain, {emitEvent: false});
    this.titleControl.setValue(this.item().title, {emitEvent: false});
    this.descriptionControl.setValue(this.item().description, {emitEvent: false});
    if(!this.auth.isSystemAdmin() && !this.adminService.isAdmin()) {
      this.domainControl.disable({emitEvent: false});
    }
  }

  save() {

    if(!this.domainControl.valid) {
      this.note.note('Domain muss das format haben: example-domain.org');
      return;
    }

    this.item.update(project => ({
      ...project,
      client_id: project.client_id.length > 0 ? project.client_id : this.adminService.clientId(),
      domain: `${this.domainControl.value}`.toLowerCase(),
      title: this.titleControl.value as string,
      description: this.descriptionControl.value as string,
    }));

    this.service.save(this.item())
      .then(this.saveSuccess)
      .catch(this.saveFailed);
  }

  saveSuccess = (item: Project) => {
    this.note.note('Gespeichert!');
    this.saved.emit(item);
  }

  saveFailed = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }

  delete() {
    this.note.confirm('Projekt löschen?', () => {
      this.service.deleteById(this.item().id)
        .then(this.deleteSuccess)
        .catch(this.deleteFailed);
    });
  }

  deleteSuccess = (item: Project) => {
    this.note.note('Gelöscht!');
    this.deleted.emit(item);
  }

  deleteFailed = () => {
    this.note.note('Löschen fehlgeschlagen!');
  }
}
