import {ChangeDetectionStrategy, Component, HostBinding, Input} from "@angular/core";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-dynamic-text,[app-dynamic-text]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <p>{{ content }}</p>
    <div class="page-editor-menu-container">
      <button mat-button>edit</button>
      <button mat-button>move up</button>
      <button mat-button>move down</button>
    </div>
  `,
  styles: [`
    :host {
      display: block;

      > p {
        margin: 16px;
        padding: 16px;
        font-size: 1.2rem;
      }
    }
  `],
  imports: [
    MatButton
  ]
})
export class DynamicTextComponent {
  @HostBinding('class.app-dynamic-component') cssComponentClass = true;
  @HostBinding('class.app-dynamic-text') cssHostClass = true;
  @Input() content: string = '';
}
