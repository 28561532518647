import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidDomain } from './is-valid-domain';

export const domainValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (!isValidDomain(value)) {
    return { invalidDomain: true };
  }
  return null;
};
