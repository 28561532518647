import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, computed,
  HostBinding,
  Inject, input,
  output,
  PLATFORM_ID,
} from "@angular/core";
import {isPlatformBrowser, JsonPipe} from "@angular/common";
import type * as L from 'leaflet';

@Component({
  selector: 'pa-map',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
<!--    <pre>center: {{ center() | json }}</pre>-->
    <div id="map" class="map"></div>
  `,
  imports: [
    JsonPipe
  ]
})
export class MapComponent implements AfterViewInit {
  @HostBinding('class.map-container') classRichMap = true;
  center = input<{latlng: L.LatLng, zoom: number}>();
  initCenter = computed(() => [this.center()?.latlng.lat, this.center()?.latlng.lng] as [number,number]);
  initZoom = computed(() => this.center()?.zoom as  number);

  mapClick = output<{latlng: L.LatLng, zoom: number}>();
  private map: L.Map | null = null;
  private L: any = {};

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      // FIXME import issue with leaflet. Only has "L.default.*" property that has the content expected in "L.*"
      // import('leaflet').then(L=> this.initMap(L)); // was working before..
      // import('leaflet').then(({default: L}) => this.initMap(L)); // maybe a solution.. research needed
      import('leaflet').then(L => {
        // cause unexpected behavior, here it will be double checked
        if(Object.hasOwn(L, 'map')) {
          console.log('map loaded');
          this.initMap(L);
        } else if(Object.hasOwn(L, 'default')) {
          console.log('map loaded (fallback "default")');
          this.initMap(L?.default);
        } else {
          console.error('leaflet import failed!');
        }
      });
    }
  }

  private initMap(L: typeof import('leaflet')) {
    console.log('L', L);
    this.L = L;
    this.map = L.map('map', {
      center: this.initCenter(),
      zoom: this.initZoom()
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
    this.map.on('click', this.mapClickEvent);
  }

  mapClickEvent = (event: any) => {
    // console.log('mapClickEvent', {map: event});
    // console.log('mapClickEvent', {latlng: event.latlng});
    // console.log('mapClickEvent', {zoom: this.map?.getZoom()});
    this.mapClick.emit({
      latlng: event.latlng,
      zoom: this.map?.getZoom() as number
    });
  }

  addMarker(latlng: L.LatLng) {
    this.L.marker(latlng).addTo(this.map);
  }
}
