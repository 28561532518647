import {computed, inject, Injectable, signal} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {I18nService} from "../features/i18n";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  closed = signal(true); // DEPRECATED
  slim = signal(true);
  router = inject(Router);
  i18n = inject(I18nService);
  activeRouteName$ = signal('dashboard');

  navigate(path: string) {
    return this.router.navigate(this.getSegments(path)());
  }

  getSegments = (path: string) => computed(() => {
    let segments = ['/', this.i18n.locale().code];
    if (path.length > 0) {
      segments = [...segments, ...path.split('/')];
    }
    return segments;
  });

  // use after login/logout
  reset() {
    this.closed.set(false);
    this.slim.set(true);
  }
}

export const setRouteName = (name: string): CanActivateFn => {
  return (route, state) => {
    inject(NavigationService).activeRouteName$.set(name);
    return true;
  };
}
