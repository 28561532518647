import {HashTable, HasId, ListFilter} from "../../types";
import {DynamicNavigationNode} from "../navigation/types";

export interface ProjectListFilter extends ListFilter {
  clientId: string;
}

export interface Project extends HasId {
  id: string;
  client_id: string;
  title: string;
  description: string;
  config: string; // JSON string
  domain: string;
}

export const projectFactory = (): Project => ({
  id: '',
  client_id: '',
  domain: '',
  title: '',
  description: '',
  config: JSON.stringify({...projectConfigFactory()}),
});

export interface ProjectDeploy extends HasId {
  id: string;
  client_id: string;
  project_id: string;
  title: string;
  description: string;
  config: string; // JSON string
  domain: string;
}

export const projectDeployFactory = (): ProjectDeploy => ({
  id: '',
  client_id: '',
  domain: '',
  title: '',
  description: '',
  config: JSON.stringify({...projectConfigFactory()}),
  project_id: ''
});

export interface ComponentConfig {
  id: string;
  type: string;
  config: HashTable<any>;
}

export const componentConfigFactory = (): ComponentConfig => ({
  id: '',
  type: '',
  config: {}
});

export interface PageConfig {
  id: string;
  path: string;
  components: Array<ComponentConfig>;
}

export const pageConfigFactory = (): PageConfig =>  ({
    id: '',
    path: '',
    components: []
});

export interface ProjectConfig {
  navigationNodes: Array<DynamicNavigationNode>;
  pages: Array<PageConfig>;
}

export const projectConfigFactory = (): ProjectConfig => ({
  navigationNodes: [],
  pages: []
})
