import {ChangeDetectionStrategy, Component, HostBinding, inject, signal} from "@angular/core";
import {JsonPipe, NgOptimizedImage} from "@angular/common";
import {AuthLoginFormComponent} from "./auth-login-form.component";
import {MatMenuItem} from "@angular/material/menu";
import {MatButton} from "@angular/material/button";
import {MatDivider} from "@angular/material/divider";
import {ProjectCardComponent} from "../../project/project-card.component";
import {AuthService} from "../services/auth.service";
import {NotificationService} from "../../../services/notification.service";
import {AdminService} from "../../../admin/admin.service";
import {ClientService} from "../../client";
import {PaKeycloakService} from "../services/pa-keycloak.service";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../../services/navigation.service";

@Component({
  selector: 'landing-page',
  standalone: true,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    JsonPipe,
    ProjectCardComponent,
    AuthLoginFormComponent,
    NgOptimizedImage,
    MatMenuItem,
    MatButton,
    MatDivider,
    RouterLink,
  ],
  template: `
    <img src="/img/logo.svg" alt="Logo: Polit@ktiv" height="120" width="234"/>
<!--    <pre>l: {{isLoggedIn()}}</pre>-->
    @if (isLoggedIn()) {
      <div class="auth-process-content">
        <div>Eingeloggt als</div>
        <div>{{ loggedInName() }}</div>

<!--        <div>-->
<!--          <button (click)="logout()" mat-raised-button>Logout</button>-->
<!--        </div>-->


        <!--        <mat-divider />-->
        <!--        <pre>-->
        <!--          isSuperAdmin: {{auth.isSystemAdmin()}}-->
        <!--          isAdmin: {{auth.isAdmin()}}-->
        <!--          isMod: {{auth.isMod()}}-->
        <!--          isUser: {{auth.isUser()}}-->
        <!--          userRole {{auth.userRole() | json }}-->
        <!--          userRoles {{auth.userRoles() | json }}-->
        <!--        </pre>-->
      </div>
<!--    } @else {-->
<!--      <login-form/>-->
    }

    @if (kcEnable()) {
      <div style="padding-top: 1rem;"></div>
<!--      <mat-divider />-->
<!--      Keycloak: (loggedIn: {{kcIsLoggedIn()}})-->
<!--      <mat-divider />-->

      @if (kcIsLoggedIn()) {
        <button (click)="kcLogout()"
                mat-raised-button>Logout</button>
      } @else {
        <button (click)="kcLogin()"
                mat-raised-button>Login</button>
        <div style="padding-top: 1rem;"></div>
        <a [routerLink]="registerLink()">Registrieren</a>
        <div style="padding-top: 1rem;"></div>
        <!--    <a [routerLink]="registerConfirmLink()">Registrieren-confirm-test</a>-->
        <a [routerLink]="resetLink()">Password vergessen?</a>
        <!--    <a [routerLink]="resetConfirmLink()">Password vergessen?-confirm-test</a>-->
      }
    }

    <!--    <mat-divider />-->
    <!--&lt;!&ndash;    <pre>kcIsLoggedIn: {{kcIsLoggedIn}}</pre>&ndash;&gt;-->
    <!--    <mat-divider />-->
  `
})
export class AuthLandingPageComponent {
  @HostBinding('class.landing-page') cssClass = true;
  @HostBinding('class.auth-process-page') cssTypeClass = true;
  private nav = inject(NavigationService);
  private note = inject(NotificationService);
  private auth = inject(AuthService);
  private adminService = inject(AdminService);
  private clientService = inject(ClientService);
  private paKeycloakService = inject(PaKeycloakService);

  registerLink = this.nav.getSegments('auth/register');
  registerConfirmLink = this.nav.getSegments('auth/register-confirm/foo-bar-foobar');
  resetLink = this.nav.getSegments('auth/password-reset');
  resetConfirmLink = this.nav.getSegments('auth/password-reset-confirm/foo-bar-foobar');

  kcEnable = signal(true);

  isLoggedIn = this.auth.isLoggedIn;
  loggedInName = this.auth.loggedInName;
  kcIsLoggedIn = this.paKeycloakService.isLoggedIn;

  logout() {
    this.auth.logout();
    this.adminService.setClient(this.clientService.defaultClient());
    this.note.note('Ausgeloggt!');
  }

  kcLogin() {
    console.log('kc login attempt');
    this.auth.kcLogin();
  }

  kcLogout() {
    console.log('kc logout attempt');
    this.auth.kcLogout();
  }
}

