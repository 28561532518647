import {Route} from "@angular/router";
import {AuthRegisterPageComponent} from "./components/auth-register-page.component";
import {AuthRegisterConfirmPageComponent} from "./components/auth-register-confirm-page.component";
import {AuthLandingPageComponent} from "./components/auth-landing-page.component";
import {AdminLayoutComponent} from "../../admin/admin-layout.component";
import {canRegisterConfirm} from "./guards/can-register-confirm";
import {AuthPasswordResetPageComponent} from "./components/auth-password-reset-page.component";
import {AuthPasswordResetConfirmPageComponent} from "./components/auth-password-reset-confirm-page.component";
import {canPasswordResetConfirm} from "./guards/can-password-reset-confirm";

export const authRoute: Route = {
  path: 'auth',
  component: AdminLayoutComponent,
  children: [
    {path: 'register', component: AuthRegisterPageComponent},
    {path: 'register-confirm/:registerKey', component: AuthRegisterConfirmPageComponent, canActivate: [canRegisterConfirm]},
    {path: 'password-reset', component: AuthPasswordResetPageComponent},
    {path: 'password-reset-confirm/:resetKey', component: AuthPasswordResetConfirmPageComponent, canActivate: [canPasswordResetConfirm]},
    {path: '', component: AuthLandingPageComponent},
  ]
}
