import {computed, inject, Injectable, signal} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {HasId, ListResponse} from "../types";

@Injectable({providedIn: "root"})
export class ApiService {
  private http = inject(HttpClient);
  xApiUrl = ''; // source from "x-api-url" host header variable set on proxy vhost, initialized in AppService.init()
  userId = signal('');
  token = signal('');
  // private bearer = computed(() => `Authorization: Bearer ${this.token()}`);
  private headers = computed(() => new HttpHeaders({ 'Authorization':`Bearer ${this.token()}`}));
  private hasToken = computed(() => this.token().length > 0);
  // private headers_ = new Headers({ 'Content-Type': 'application/json'});

  apiUrl = (path: string) => {
    return `${this.xApiUrl.length > 0 ? this.xApiUrl : environment.apiUrl}/${path}`;
  }

  fetchList = <T extends HasId>(path: string, params: HttpParams = new HttpParams(), auth = false): Promise<ListResponse<T>> => {
    params = params.set('login_id', this.userId());
    let options = auth && this.hasToken() ? {params, headers: this.headers()}: {params};
    const response = firstValueFrom(this.http.get<ListResponse<T>>(this.apiUrl(path), options));
    response.catch(error => console.log('Error:', path, error));
    return response;
  }

  fetchById = <T extends HasId>(path: string, id: string, auth = false): Promise<T> => {
    return this.fetch<T>(`${path}/${id}`, auth);
  }

  save = <T extends HasId>(path: string, item: T, auth = false): Promise<T> => {
    const params = {...item, login_id: this.userId};

    let response = undefined;

    if(item.id.length > 0) {
      if(auth && this.hasToken()) {
        response = firstValueFrom(this.http.put<T>(`${this.apiUrl(path)}/${item.id}`, params, {headers: this.headers()}));

      } else {
        response = firstValueFrom(this.http.put<T>(`${this.apiUrl(path)}/${item.id}`, params));
      }

    } else {
      if(auth && this.hasToken()) {
        response = firstValueFrom(this.http.post<T>(this.apiUrl(path), params, {headers: this.headers()}));
      } else {
        response = firstValueFrom(this.http.post<T>(this.apiUrl(path), params));
      }
    }

    // const response = item.id.length > 0
    //   ? firstValueFrom(this.http.put<T>(`${this.apiUrl(path)}/${item.id}`, params))
    //   : firstValueFrom(this.http.post<T>(this.apiUrl(path), params));
    response.catch(error => console.log('Error:', path, params, error));
    return response;
  }

  deleteById = <T extends HasId>(path: string, id: string, auth = false): Promise<T> => {
    const response = auth && this.hasToken()
      ? firstValueFrom(this.http.delete<T>(this.apiUrl(`${path}/${id}`)))
      : firstValueFrom(this.http.delete<T>(this.apiUrl(`${path}/${id}`), {headers: this.headers()}));
    response.catch(error => console.log('Error:', path, error));
    return response;
  }

  fetch = <T>(path: string, auth = false): Promise<T> => {
    const response = auth && this.hasToken()
      ? firstValueFrom(this.http.get<T>(this.apiUrl(path), {headers: this.headers()}))
      : firstValueFrom(this.http.get<T>(this.apiUrl(path)));
    response.catch(error => console.log('Error:', path, error));
    return response;
  }

  post<T>(path: string, body: any = {}, auth = false): Promise<T> {
    const response =  auth && this.hasToken()
      ? firstValueFrom(this.http.post<T>(this.apiUrl(path), body, {headers: this.headers()}))
      : firstValueFrom(this.http.post<T>(this.apiUrl(path), body));
    response.catch(error => console.log('Error:', path, body, error));
    return response;
  }
}
