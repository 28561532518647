import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit} from '@angular/core';
import {ClientService} from "./client.service";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AdminClientListComponent} from "./admin-client-list.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NavigationService} from "../../services/navigation.service";
import {AuthService} from "../auth/services/auth.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'app-admin-client-list-page',
  standalone: true,
  imports: [
    AdminClientListComponent,
    AdminPageHeaderComponent,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <admin-page-header title="Mandanten Verwaltung"/>

    <div class="toolbar">
      @if(auth.isSystemAdmin()) {
        <div class="flex button-container">
          <button [routerLink]="link$('admin/client')()" mat-button>
            <mat-icon>add</mat-icon>
            Mandant Erstellen
          </button>
        </div>
      }

      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>

    </div>

    <div class="list-container">
      <app-admin-client-list/>
    </div>
  `,
})
export class AdminClientListPageComponent implements OnInit {
  @HostBinding('class.admin-page') cssClass = true;
  private service = inject(ClientService);
  private nav = inject(NavigationService);
  auth = inject(AuthService);
  link$ = this.nav.getSegments;

  public search = new FormControl(
    this.service.filter().search
  );

  reload = () => {
    this.service.filter.update(
      value => ({...value, search: `${this.search.value}`})
    );
    this.service.fetchList();
  }

  ngOnInit() {
    this.service.fetchList();
  }
}
