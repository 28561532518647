import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit} from "@angular/core";
import {ProjectService} from "./project.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {PinboardTagService} from "../pinboard/pinboard-tag.service";
import {PinboardTag, pinboardTagFactory} from "../pinboard/types";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NavigationService} from "../../services/navigation.service";
import {NotificationService} from "../../services/notification.service";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";
import {AdminService} from "../../admin/admin.service";

@Component({
  selector: 'project-tag-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    RouterLink,
    MatButton,
    MatIcon,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel
  ],
  template: `
    <admin-page-header [title]="title()" />
    <div class="toolbar">
      <div class="button-container">
        <button [routerLink]="link('admin/projects')()" mat-button>
          <mat-icon>arrow_back</mat-icon>
          Zur Liste
        </button>
      </div>
    </div>

    <div class="content-container">
        <div class="tag-form">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Erstellen</mat-label>
              <input [formControl]="tagControl" matInput />
            </mat-form-field>
          </div>

          <div class="button-container">
            <button (click)="saveTag()" mat-raised-button>Absenden</button>
          </div>
        </div>
        <div class="tag-list">
          @for (tag of tags(); track tag) {
            {{tag.name}}<br />
          }
        </div>
      </div>
  `
})

export class AdminProjectTagPageComponent implements OnInit {
  @HostBinding('class.project-tag-page') cssClass = true;
  @HostBinding('class.admin-page') classAdminPage = true;
  private projectService = inject(ProjectService);
  private adminService = inject(AdminService);
  private nav = inject(NavigationService);
  private pinboardTagService = inject(PinboardTagService);
  private note = inject(NotificationService);
  tags = this.pinboardTagService.items;
  tagControl = new FormControl('');
  link = this.nav.getSegments;
  project = this.projectService.currentItem;
  title = computed(() => `TAG Verwaltung für Projekt "${this.project().title}"`);

  ngOnInit() {
    this.pinboardTagService.filterSetClientId(this.adminService.clientId());
    this.pinboardTagService.filterSetProjectId(this.project().id);
    this.pinboardTagService.loadList();
  }

  saveTag() {
    const value = `${this.tagControl.value}`;
    if (value.length < 1) {
      return;
    }

    const newTag: PinboardTag =  {
      ...pinboardTagFactory(),
      client_id: this.adminService.clientId(),
      project_id: this.project().id,
      name: value
    };

    this.pinboardTagService.save(newTag)
      .then(this.saveTagSuccess)
      .catch(this.saveTagFail)
  }

  saveTagSuccess = () => {
    this.note.note('Gespeichert!');
    this.tagControl.setValue('');
    this.pinboardTagService.loadList();
  }

  saveTagFail = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }
}
