import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {UserService} from "./user.service";
import {AsyncPipe, JsonPipe} from "@angular/common";
import {MatTableModule} from "@angular/material/table";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    AsyncPipe,
    JsonPipe,
    MatTableModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    RouterLink
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<table mat-table [dataSource]="items$()">
<!--    <ng-container matColumnDef="role">-->
<!--      <th mat-header-cell *matHeaderCellDef> Rolle </th>-->
<!--      <td mat-cell *matCellDef="let item"> {{item.role}}</td>-->
<!--    </ng-container>-->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let item"> {{item.email}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let item"> {{item.first_name}} {{item.last_name}}</td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell class="actions" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <div class="flex-row">
          <button mat-button
                  color="primary"
                  [routerLink]="roleLink$(item.id)()">
            <mat-icon>edit</mat-icon>
            Rollen
          </button>
          <button mat-button
                  color="primary"
                  [routerLink]="detailLink$(item.id)()">
            <mat-icon>search</mat-icon>
            Details
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  `,
})
export class AdminUserListComponent {

  private service = inject(UserService);
  private nav = inject(NavigationService);
  detailLink$ = (id: string) => this.nav.getSegments(`admin/user/${id}`);
  roleLink$ = (id: string) => this.nav.getSegments(`admin/user/${id}/roles`);
  items$ = this.service.items;
  displayedColumns: string[] = ['email', 'name', 'id'];

  constructor() {
    this.service.fetchList();
  }

  // getDetailUrl(item: User): string {
  //   return `/admin/user/${item.id}`;
  // }
}
