import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit, signal} from '@angular/core';
import {AdminUserListComponent} from "./admin-user-list.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {UserService} from "./user.service";
import {MatDrawer, MatDrawerContainer, MatDrawerContent} from "@angular/material/sidenav";
import {MatFormField, MatLabel} from "@angular/material/select";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-admin-user-list-page',
  standalone: true,
  imports: [
    AdminUserListComponent,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDrawerContainer,
    MatDrawer,
    MatDrawerContent,
    MatFormField,
    MatLabel,
    AdminPageHeaderComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <admin-page-header title="Benutzerverwaltung" />

    <div class="toolbar">
      <div class="flex button-container">
<!--        <button [routerLink]="link$('admin/user')()" mat-button>-->
<!--          <mat-icon>add</mat-icon>-->
<!--          Nutzer Erstellen-->
<!--        </button>-->
      </div>
      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

<!--    <div class="content-container">-->
<!--      <mat-drawer-container class="content-layout">-->
<!--        <mat-drawer mode="side" opened>-->
<!--          <h2>Gruppen</h2>-->
<!--          <div>-->
<!--            <mat-form-field appearance="outline">-->
<!--              <mat-label>Name der Gruppe</mat-label>-->
<!--              <input matInput [formControl]="groupControl"/>-->
<!--            </mat-form-field>-->
<!--            <button (click)="addGroup()" mat-raised-button>Hinzufügen</button>-->

<!--            <div class="group-list">-->
<!--              @for (group of groups$(); track group) {-->
<!--                <div class="group-detail">{{ group.name }}</div>-->
<!--              }-->
<!--            </div>-->
<!--          </div>-->
<!--        </mat-drawer>-->
<!--        <mat-drawer-content>-->
<!--          <app-user-list/>-->
<!--        </mat-drawer-content>-->
<!--      </mat-drawer-container>-->
<!--    </div>-->
    <div class="list-container">
      <app-user-list/>

    </div>

  `,
  styles: [`
    .content-layout {
      min-height: 450px;
      .mat-drawer {
        width: 250px;
        padding: 0 16px;
        margin: 8px;
        h2 {
          font-size: 1.2rem;
          margin: 16px 0;
        }
        .group-list {
          margin-top: 16px;
          .group-detail {
            font-weight: bold;
            line-height: 2rem;
          }
        }
      }
    }
  `]
})
export class AdminUserListPageComponent implements OnInit{
  @HostBinding('class.admin-page') classAdminPage = true;
  private service = inject(UserService);
  private nav = inject(NavigationService);
  link$ = this.nav.getSegments;
  public search = new FormControl(this.service.filter().search);
  groups$ = signal<Array<any>>([]);
  groupControl = new FormControl('');

  ngOnInit() {
    this.service.fetchList();
  }

  reload = () => {
    this.service.filter.update(
      value => ({...value, search: `${this.search.value}`})
    );
    this.service.fetchList();
  }

  addGroup() {
    let name = `${this.groupControl.value}`;
    if(name.length < 1) {
      return;
    }
    this.groups$.update(groups => [...groups, {name}])
  }
}
