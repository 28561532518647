import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, Input, OnInit} from "@angular/core";
import {PinboardEntryService} from "./pinboard-entry.service";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {RouterLink} from "@angular/router";
import {MatDivider} from "@angular/material/divider";
import {AdminPinboardEntryListComponent} from "./admin-pinboard-entry-list.component";
import {PinboardTagService} from "./pinboard-tag.service";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {JsonPipe} from "@angular/common";
import {ProjectService} from "../project/project.service";
import {NavigationService} from "../../services/navigation.service";
import {NotificationService} from "../../services/notification.service";
import {Project} from "../project";
import {ModerationStatus} from "../../types";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";
import {AdminService} from "../../admin/admin.service";

@Component({
  selector: 'pinboard-entry-list-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent,
    MatButton,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule,
    RouterLink,
    MatDivider,
    AdminPinboardEntryListComponent,
    MatOption,
    MatSelect,
    JsonPipe
  ],
  template: `
    <admin-page-header title="Pinnwand"/>
    <div class="toolbar">
      <div class="flex button-container">
      </div>
      <div>
        <mat-form-field>
          <mat-label>Tags</mat-label>
          <mat-select [formControl]="tagsControl" multiple>
            @for (tag of tags(); track tag) {
              <mat-option [value]="tag.id">{{tag.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [formControl]="moderationStatusControl">
            <mat-option value="">- alle -</mat-option>
            <mat-option value="pending">wartend</mat-option>
            <mat-option value="approved">freigegeben</mat-option>
            <mat-option value="rejected">abgelehnt</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Diskussionskreis</mat-label>
          <mat-select [formControl]="projectControl">
            @for (project of projects(); track project.id) {
              <mat-option [value]="project"> {{project.title}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="list-container flex-1">
      <admin-pinboard-list />
    </div>
  `
})
export class AdminPinboardEntryListPageComponent implements OnInit {
  @HostBinding('class.admin-page') classAdminPage = true;
  private nav = inject(NavigationService);
  private service = inject(PinboardEntryService);
  private adminService = inject(AdminService);
  private pinboardTagService = inject(PinboardTagService);
  private projectService = inject(ProjectService);
  projects = this.projectService.items;
  selectedProject = computed(() => this.projects().find(project => project.id === this.service.filter().projectId) || null);
  link = this.nav.getSegments;
  search = new FormControl(this.service.filter().search);
  tags = this.pinboardTagService.items;
  tagsControl = new FormControl<Array<string>>([]);
  projectControl = new FormControl(this.selectedProject());
  moderationStatusControl = new FormControl<ModerationStatus | ''>(this.service.filter().moderationStatus);

  ngOnInit() {
    this.service.filterSetClientId(this.adminService.clientId());
    this.pinboardTagService.filterSetClientId(this.adminService.clientId());
    this.projectControl.valueChanges.subscribe(this.updateFilterProject);
    this.moderationStatusControl.valueChanges.subscribe(this.updateFilterModerationStatus);
    this.tagsControl.valueChanges.subscribe(this.updateFilterTagIds);
    this.updateFilterProject(this.projectControl.value);
  }

  private updateFilterProject = (project: Project | null) => {
    const projectId = project ? project.id : '';
    this.service.filterSetProjectId(projectId);
    this.service.filterSetTagIds([]);
    this.tagsControl.setValue([], {emitEvent: false});
    this.pinboardTagService.filterSetProjectId(projectId);
    this.pinboardTagService.loadList().then(() => this.reload());
  }

  private updateFilterModerationStatus = (moderationStatus: ModerationStatus | '' | null)=> {
    if (moderationStatus === null) {
      moderationStatus = '';
    }
    this.service.filterSetModerationStatus(moderationStatus);
    this.reload();
  }

  private updateFilterTagIds = (tagIds: Array<string> | null) => {
    if(tagIds === null) {
      tagIds = [];
    }
    this.service.filterSetTagIds(tagIds);
    this.reload();
  }

  reload = () => {
    this.service.filterSetSearch(`${this.search.value}`);
    this.service.loadList();
  }
}
