import {ChangeDetectionStrategy, Component, HostBinding} from "@angular/core";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminPageHeaderComponent
  ],
  template: `
    <admin-page-header title="Dashboard"/>
    coming soon..
  `
})
export class AdminDashboardPageComponent {
  @HostBinding('class.admin-page') cssClass = true;
}
