import {Routes} from '@angular/router';
import {canActivateI18n} from "./lib/features/i18n";
import {environment} from "../environments/environment";
import {canActivateInitializedApp} from "./lib/services/app-init.service";
import {adminRoute} from "./lib/admin/admin.route";
import {deployRoute} from "./lib/deploy/deploy.route";
import {authRoute} from "./lib/features/auth/auth.route";

export const routes: Routes = [
  {
    path: '', // init path
    canActivate: [canActivateInitializedApp],
    children: [
      {
        path: ':locale',
        canActivate: [canActivateI18n],
        children: [
          authRoute,
          adminRoute,
          deployRoute,
          {path: '**', redirectTo: `auth`, pathMatch: 'full'},
        ]
      }, {path: '', redirectTo: `/${environment.defaultLocaleCode}`, pathMatch: 'full'},
    ]
  },
  {path: '**', redirectTo: '', pathMatch: 'full'},
];

