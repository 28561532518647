import {ChangeDetectionStrategy, Component, HostBinding, inject, OnInit, output} from "@angular/core";
import {Asset} from "./types";
import {AdminAssetListComponent} from "./admin-asset-list.component";
import {AssetService} from "./asset.service";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {AdminPageHeaderComponent} from "../../admin/admin-page-header.component";
import {AdminService} from "../../admin/admin.service";

@Component({
  selector: 'admin-asset-selector',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AdminAssetListComponent,
    AdminPageHeaderComponent,
    FormsModule,
    MatButton,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule,
  ],
  template: `
    <admin-page-header title="Bild auswählen" />
    <div class="toolbar">

      <div class="flex button-container">
        <input #uploadField hidden="hidden" type="file"
               onclick="this.value=null"
               (change)="onFileSelected($event)" [accept]="accept"/>

        <button (click)="close.emit()" mat-button>
          Auswahl schließen
        </button>
        <button (click)="uploadField.click()" mat-button>
          <mat-icon>upload</mat-icon>
          Upload
        </button>
      </div>

      <div>
        <mat-form-field>
          <mat-label>Suche</mat-label>
          <input [formControl]="search"
                 (keydown.enter)="reload()"
                 type="search"
                 matInput>
          <button (click)="reload()"
                  aria-label="search"
                  mat-icon-button
                  matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>

    </div>
    <div class="list-container">
      <admin-asset-list (assetSelected)="assetSelectHandler($event)"
                        [selectable]="true"
                        [selectType]="'images'" />
    </div>
  `
})
export class AdminAssetSelectorComponent implements OnInit {
  @HostBinding('class.xyz-component') dssClass = true;
  assetSelected = output<Asset>();
  close = output();
  service = inject(AssetService);
  adminService = inject(AdminService);
  accept = this.service.assetTypesAccepted;
  public search = new FormControl(
    this.service.filter().search
  );

  onFileSelected(event: any) {
    console.log('onFileSelected', event);
    const file:File = event.target.files[0];
    if (file) {
      this.service.upload(file, this.adminService.clientId()).then(asset => {
        this.reload();
      });
    }
  }

  assetSelectHandler(asset: Asset) {
    this.assetSelected.emit(asset);
    this.close.emit();
  }

  reload = () => {
    this.service.filterSetSearch(`${this.search.value}`);
    this.service.fetchList();
  }

  ngOnInit() {
    this.service.filterSetClientId(this.adminService.clientId());
    this.reload();
  }
}
