import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent
} from "@angular/material/dialog";
import {MatDivider} from "@angular/material/divider";
import {JsonPipe} from "@angular/common";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'confirm-dialog',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDivider,
    JsonPipe,
    MatButton,
    MatDialogClose,
    MatDialogActions,
    MatDialogContent
  ],
  template: `
    <mat-dialog-content>
      <p>{{data.message}}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button [mat-dialog-close]="false" mat-button>Nein</button>
      <button [mat-dialog-close]="true" mat-button>Ja</button>
    </mat-dialog-actions>
  `
})
export class ConfirmDialogComponent {
  data = inject<{message: string}>(MAT_DIALOG_DATA);
}
