import {HasId} from "../../types";

export interface Site extends HasId {
  id: string;
  label: string;
  title: string;
}

export const siteFactory = ():Site => ({id: '', label: '', title: ''});

