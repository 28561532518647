import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, model, signal} from "@angular/core";
import {DynamicHeadlineComponent, DynamicTextComponent} from "../../components/dynamic";
import {DynamicHeroComponent} from "../../components/dynamic/dynamic-hero.component";
import {DynamicSiteHeaderComponent} from "../navigation/dynamic-site-header.component";
import {RichMapContainerComponent} from "../map/rich-map-container.component";
import {PageEditorService} from "./page-editor.service";
import {PinboardComponent} from "../pinboard/pinboard.component";
import {HeroTeaserComponent} from "./components/hero-teaser/hero-teaser.component";
import {MatDivider} from "@angular/material/divider";
import {JsonPipe} from "@angular/common";
import {ComponentConfig} from "../project";
import {MatButton, MatIconButton} from "@angular/material/button";
import {ArticleComponent} from "./components/article/article.component";
import {PinboardEntryFormComponent} from "../pinboard/pinboard-entry-form.component";
import {MatIcon} from "@angular/material/icon";
import {NotificationService} from "../../services/notification.service";
import {AdminService} from "../../admin/admin.service";

@Component({
  selector: 'page-editor-component-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DynamicHeadlineComponent,
    DynamicHeroComponent,
    DynamicSiteHeaderComponent,
    DynamicTextComponent,
    RichMapContainerComponent,
    PinboardComponent,
    HeroTeaserComponent,
    MatDivider,
    JsonPipe,
    MatButton,
    MatIcon,
    ArticleComponent,
    PinboardEntryFormComponent,
    MatIconButton
  ],
  template: `
    <div class="flex-row">
      <button (click)="editNavigation()" mat-button>Bearbeiten</button>
    </div>
    @for (component of components(); track component) {
      @switch (component.type) {
        @case ('hero-teaser') {
          <hero-teaser [component]="component"/>
        }
        @case ('rich-map') {
          <div class="page-content-container">
            <rich-map-container [component]="component"/>
          </div>
        }
        @case ('map') {
          <div class="page-content-container">
              <rich-map-container [component]="component"/>
          </div>
        }
        @case ('pinboard') {
          <div class="page-content-container">
            <pinboard [component]="component"
                      [clientId]="clientId()"
                      [projectId]="projectId()" />
            <pinboard-entry-form [pinboardComponent]="component"
                                 [clientId]="clientId()"
                                 [projectId]="projectId()" />
          </div>
        }
        @case ('article') {
          <div class="page-content-container">
            <article-component [component]="component" />
          </div>
        }
      }
      <div class="flex-row">
        <button (click)="deleteComponent(component)" mat-button>Löschen</button>
        <button (click)="editComponent(component)" mat-button>Bearbeiten</button>
        <button (click)="moveComponentUp(component)" mat-icon-button><mat-icon>arrow_upward</mat-icon></button>
        <button (click)="moveComponentDown(component)" mat-icon-button><mat-icon>arrow_downward</mat-icon></button>
      </div>
    }

<!--    <mat-divider />-->
<!--    <pre>components: {{components() | json}}</pre>-->
<!--    <pre>config {{ config() | json}}</pre>-->
<!--    <mat-divider />-->
  `
})
export class PageEditorComponentListComponent {
  @HostBinding('class.page-editor-component-list') cssCLass = true;
  private service = inject(PageEditorService);
  private note = inject(NotificationService);
  private adminService = inject(AdminService);
  clientId = this.adminService.clientId;
  projectId = this.service.projectId;
  config = this.service.config;
  components= computed(() => this.service.page().components);

  editNavigation() {
    this.service.navigateToNavigationEditor();
  }

  editComponent(component: ComponentConfig) {
    // console.log('editComponent', component);
    this.service.navigateToComponent(component);
  }

  deleteComponent(component: ComponentConfig) {
    // console.log('deleteComponent', component);
    this.note.confirm('Komponente löschen?', () => {
      this.service.deleteComponent(component);
    });
  }

  moveComponentUp(component: ComponentConfig) {
    console.log('moveComponentUp (component list)', component);
    this.service.moveComponentUp(component);
  }

  moveComponentDown(component: ComponentConfig) {
    console.log('moveComponentDown (component list)', component);
    this.service.moveComponentDown(component);
  }

  toggleEditMode() {
    console.log('toggle editMode');
    // TODO implement
  }
}
