import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {RouterLink} from "@angular/router";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {AsyncPipe, JsonPipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {NavigationService} from "../services/navigation.service";
import {DeployService} from "../deploy/deploy.service";
import {AdminService} from "./admin.service";
import {AuthService} from "../features/auth/services/auth.service";

@Component({
  selector: 'app-admin-layout-navigation',
  standalone: true,
  imports: [
    RouterLink,
    MatListModule,
    MatButtonModule,
    AsyncPipe,
    MatIcon,
    JsonPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-nav-list>
      <mat-list-item (click)="toggleWidth()">
        <div class="slim-toggle-button-container">
          <mat-icon>keyboard_double_arrow_left</mat-icon>
        </div>
      </mat-list-item>

      <mat-divider/>

      <mat-list-item [routerLink]="link$('admin')()">
        <mat-icon matListItemIcon>home</mat-icon>
        <div matListItemTitle>Dashboard</div>
      </mat-list-item>
      <mat-list-item [routerLink]="link$('admin/projects')()">
        <mat-icon matListItemIcon>folder_special</mat-icon>
        <div matListItemTitle>Diskussionskreis</div>
      </mat-list-item>
      <mat-list-item [routerLink]="link$('admin/pinboard')()">
        <mat-icon matListItemIcon>assignment</mat-icon>
        <div matListItemTitle>Pinnwand</div>
      </mat-list-item>
      <mat-list-item [routerLink]="link$('admin/map-entries')()">
        <mat-icon matListItemIcon>map</mat-icon>
        <div matListItemTitle>Map</div>
      </mat-list-item>

      @if (canAccessSettingsAdministration()) {
        <mat-list-item [routerLink]="link$('admin/assets')()">
          <mat-icon matListItemIcon>image</mat-icon>
          <div matListItemTitle>Assets</div>
        </mat-list-item>
      }
      <mat-divider/>

      @if (canAccessUserAdministration()) {
        <mat-list-item [routerLink]="link$('admin/users')()">
          <mat-icon matListItemIcon>people_alt</mat-icon>
          <div matListItemTitle>Nutzerverwaltung</div>
        </mat-list-item>
      }

      <!--      @if(canAccessSettingsAdministration()) {-->
      <!--        <mat-list-item [routerLink]="link$('admin/settings')()">-->
      <!--          <mat-icon matListItemIcon>settings</mat-icon>-->
      <!--          <div matListItemTitle>Einstellungen</div>-->
      <!--        </mat-list-item>-->
      <!--      }-->

      @if (canAccessSettingsAdministration()) {
        <mat-list-item [routerLink]="link$('admin/clients')()">
          <mat-icon matListItemIcon>badge</mat-icon>
          <div matListItemTitle>Clients/Mandanten</div>
        </mat-list-item>
      }

      <mat-divider/>
      <mat-list-item [routerLink]="link$('auth')()">
        <mat-icon matListItemIcon>person</mat-icon>
        <div matListItemTitle>Profil</div>
      </mat-list-item>
      <mat-list-item [routerLink]="link$('admin/info')()">
        <mat-icon matListItemIcon>info</mat-icon>
        <div matListItemTitle>Info</div>
      </mat-list-item>

      @if (hasActiveDeploy()) {
        <mat-divider/>
        <mat-list-item [routerLink]="link$('')()">
          <mat-icon matListItemIcon>arrow_back</mat-icon>
          <div matListItemTitle>Zur Aktiven Seite</div>
        </mat-list-item>
      }



      <!--      <mat-divider/>-->
      <!--      @if(canAccessSettingsAdministration()) {-->
      <!--        <mat-list-item [routerLink]="link$('admin/dev')()">-->
      <!--          <mat-icon matListItemIcon>construction</mat-icon>-->
      <!--          <div matListItemTitle>dev</div>-->
      <!--        </mat-list-item>-->
      <!--      }-->

    </mat-nav-list>
  `,
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      min-width: 250px;
      > mat-list {
        flex-grow: 1;
        mat-list-item {
          cursor: pointer;
        }
      }
    }
  `]
})
export class AdminNavigationComponent {
  private nav = inject(NavigationService);
  private deployService = inject(DeployService);
  private adminService = inject(AdminService);
  hasActiveDeploy = this.deployService.hasActiveDeploy;
  canAccessUserAdministration = this.adminService.canAccessUserAdministration;
  canAccessSettingsAdministration = this.adminService.canAccessSettingsAdministration;
  link$ = this.nav.getSegments;
  toggleWidth()  {
    this.nav.slim.set(!this.nav.slim());
  }
}
