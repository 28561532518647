import {CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {DeployService} from "./deploy.service";
import {NavigationService} from "../services/navigation.service";

export const canViewDeploy: CanActivateFn = (route, state) => {
  // console.log('canViewDeploy guard executed', { route, state });
  const service = inject(DeployService);
  const nav = inject(NavigationService);
  if(service.hasActiveDeploy()) {
    return true;
  }

  nav.navigate('auth');
  return false;
}

export const canViewDeployPage: CanActivateFn = (route, state) => {
  // console.log('canViewProjectPage guard executed', { route, state });
  const pageName: string = route.params['page'];
  // console.log('page', pageName);
  const deployViewService = inject(DeployService);
  const nav = inject(NavigationService);

  const config = deployViewService.config()
  const nodes = config.navigationNodes;
  let node = nodes.find(n => n.name === pageName);

  // one more try with '/' coz invalid data coed by missing validator
  if(!node) {
    node = nodes.find(n => n.name === `/${pageName}`);
  }

  if(!node) {
    console.log('Error: Config with no first page found!', {deployId: deployViewService.deploy().id})
    nav.navigate('');
    return false;
  }

  // console.log('node', node);

  const pageConfig = deployViewService.config().pages.find(page => page.id === node.target_page_id);
  if(!pageConfig) {
    console.log('Error: Invalid node config found!', {deployId: deployViewService.deploy().id, pageName})
    nav.navigate('');
    return false;
  }

  // console.log('pageConfig found', pageConfig);
  deployViewService.page.set(pageConfig);
  return true;
}

export const canViewDeployPageFirst: CanActivateFn = (route, state) => {
  // console.log('canViewDeployPageFirst guard executed', { route, state });
  const deployService = inject(DeployService);
  const config = deployService.config()
  const nodes = config.navigationNodes;
  const node = nodes.find(n => n.name === '');
  if(!node) {
    console.log('Error: Config with no first page found!', {deployId: deployService.deploy().id})
    return false;
  }

  // console.log('node', node);
  const pageConfig = deployService.config().pages.find(page => page.id === node.target_page_id);
  // console.log('pageConfig first', pageConfig);

  if(!pageConfig) {
    console.log('Error: Invalid first node config found!', {deployId: deployService.deploy().id})
    return false;
  }

  // console.log('pageConfig is fine', pageConfig);
  deployService.page.set(pageConfig);
  return true;
}
