
export interface ListFilter {
  search: string;
  page: number;
  size: number;
  orderBy: string;
  direction: 'asc' | 'desc';
}

export const listFilterFactory = (): ListFilter => ({
  search: '',
  page: 0,
  size: 50,
  orderBy: 'id',
  direction: 'desc'
})
