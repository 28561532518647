import {ChangeDetectionStrategy, Component, computed, Input} from "@angular/core";
import {Project, projectFactory} from "./types";
import {JsonPipe} from "@angular/common";
import {MatCard, MatCardContent, MatCardFooter, MatCardHeader} from "@angular/material/card";
import {MatButton} from "@angular/material/button";
import {MatDivider} from "@angular/material/divider";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-project-card',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    JsonPipe,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardFooter,
    MatButton,
    MatDivider,
    MatIcon
  ],
  template: `
    <mat-card>
      <mat-card-header>
        <div class="header-container">
          <div class="time-left-container">
            <p>Noch 2 Monate</p>
            <div class="time-meter"><div></div></div>
          </div>
          <div class="detail-ling-container"><button mat-raised-button>Zur Umfrage</button></div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="img-container"><div class="placeholder" style="background-image: url({{imgUrl}});"></div></div>
        <h3 class="headline">{{projekt.title}}</h3>
        <p class="text">{{shorten(projekt.description, 250)}}</p>
      </mat-card-content>
      <mat-card-footer>
        <mat-divider />
        <div class="footer-container">
<!--          <div class="members">Member</div>-->
          <div class="spacer"></div>
          <div class="badges-list-container">
            <div class="badge">
              <mat-icon>lightbulb_outline</mat-icon> 23
            </div>
            <div class="badge">
              <mat-icon>forum</mat-icon> 55
            </div>
          </div>
        </div>
      </mat-card-footer>
    </mat-card>
<!--    <pre>{{projekt|json}}</pre>-->
  `,
  styles: [`:host {
    display: block;
    //outline: solid 1px black;
    //margin: 16px;
    //padding: 16px;
    width: 450px;
    //&:hover {
    //  outline-width: 2px;
    //}

    .mat-mdc-card-header {
      flex-direction: column;
      margin-bottom: 16px;
    }

    .header-container {
      display: flex;
      flex-direction: row;
      .time-left-container {
        flex-grow: 1;
        .time-meter {
          --height: 5px;
          position: relative;
          background-color: #CCCCCC;
          height: var(--height);
          width: 200px;
          > div {
            height: var(--height);
            width: 35%;
            background-color: orange;
          }
        }
      }
    }
    .img-container {
      .placeholder {
        height: 250px;
        background-color: orangered;
      }
    }
    .headline {
      font-size: 1.6rem;
      margin: 8px 0;
    }

    .footer-container {
      display: flex;
      flex-direction: row;
      margin: 16px;
      .spacer {
        flex-grow: 1;
      }
      .badges-list-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }
    }
  }`]
})
export class ProjectCardComponent {
  @Input() projekt: Project = projectFactory();
  get imgUrl() {
    return  `'/img/projects/${this.projekt.id}.png'`;
  }
  shorten = (content: string, count: number) => {
    return content.substring(0, count);
  }
}
