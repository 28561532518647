import {ChangeDetectionStrategy, Component, computed, HostBinding, inject, OnInit, signal} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {MatDrawer, MatDrawerContainer, MatDrawerContent} from "@angular/material/sidenav";
import {JsonPipe} from "@angular/common";
import {MatFormField, MatLabel, MatOption, MatSelect, MatSuffix} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDivider} from "@angular/material/divider";
import {PageEditorService} from "./page-editor.service";
import {MatButton, MatIconButton} from "@angular/material/button";
import {
  DynamicHeadlineComponent,
  DynamicHeadlineFormComponent,
  DynamicTextComponent
} from "../../components/dynamic";
import {MatIcon} from "@angular/material/icon";
import {MapComponent} from "../map/map.component";
import {RichMapContainerComponent} from "../map/rich-map-container.component";
import {DynamicSiteHeaderComponent} from "../navigation/dynamic-site-header.component";
import {DynamicHeroComponent} from "../../components/dynamic/dynamic-hero.component";
import {PageEditorComponentListComponent} from "./page-editor-component-list.component";
import {DevBlockComponent} from "../dev/dev-block.component";
import {PageEditorComponentSelectorComponent} from "./page-editor-component-selector.component";
import {NavigationService} from "../../services/navigation.service";
import {NotificationService} from "../../services/notification.service";
import {ProjectService} from "../project/project.service";

@Component({
  selector: 'page-editor-layout',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    MatDrawerContainer,
    MatDrawer,
    MatDrawerContent,
    JsonPipe,
    MatSelect,
    MatFormField,
    MatOption,
    MatLabel,
    MatDivider,
    ReactiveFormsModule,
    MatButton,
    DynamicHeadlineComponent,
    DynamicTextComponent,
    DynamicHeadlineFormComponent,
    MapComponent,
    MatIcon,
    MatIconButton,
    MatSuffix,
    RichMapContainerComponent,
    DynamicSiteHeaderComponent,
    DynamicHeroComponent,
    PageEditorComponentListComponent,
    DevBlockComponent,
    PageEditorComponentSelectorComponent,
  ],
  template: `
    <dynamic-site-header/>
    <page-editor-component-list />
    <page-editor-component-selector />
    <!-- TODO implement controls layer -->
<!--    <mat-divider />-->
    <div class="page-editor-action-container">
      <div class="project-label">{{domain()}}</div>
      <button (click)="save()" mat-raised-button>Speichern</button>
      <button (click)="deploy()" mat-raised-button>Deploy</button>
    </div>
  `
})
export class PageEditorLayoutComponent {
  @HostBinding('class.page-editor-layout') cssCLass = true;
  private service = inject(PageEditorService);
  private nav = inject(NavigationService);
  private note = inject(NotificationService);
  private projectService = inject(ProjectService);
  domain = computed(() => this.projectService.currentItem().domain);

  deploy() {
    this.note.confirm(
      'Deploy ausführen?',
      () => this.projectService.deploy(this.service.project().id)
        .then(this.deploySuccess)
        .catch(this.deployFailure)
    );
  }

  deploySuccess = () => {
    this.note.note('Deployed!');
    // this.nav.navigate('admin/projects');
  }

  deployFailure = () => {
    this.note.note('Deploy fehlgeschlagen!');
  }


  save() {
    this.service.save()
      .then(this.saveSuccess)
      .catch(this.saveFailure);
  }

  saveSuccess = () => {
    this.note.note('Gespeichert!');
    this.nav.navigate('admin/projects');
  }

  saveFailure = () => {
    this.note.note('Speichern fehlgeschlagen!');
  }
}
