import {HasId} from "../../types";

export interface Client extends HasId {
  id: string;
  name: string;
  created: string;
  modified: string;
}

export const clientFactory = ():Client => ({
  id: '',
  name: '',
  created: '',
  modified: ''
});

