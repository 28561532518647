import {ChangeDetectionStrategy, Component, HostBinding, Input} from "@angular/core";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-dynamic-headline,[app-dynamic-headline]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    MatIconButton,
    MatIcon
  ],
  template: `
    <h1>{{ content }}</h1>
    <div class="page-editor-menu-container">
      <button mat-button>edit</button>
      <button mat-button>move up</button>
      <button mat-button>move down</button>
<!--      <button mat-icon-button><mat-icon>arrow_down</mat-icon></button>-->
    </div>
  `,
  styles: [`
    :host {
      display: block;

      > h1 {
        margin: 16px;
        padding: 16px;
        font-size: 2.5rem;
      }
    }
  `]
})
export class DynamicHeadlineComponent {
  @HostBinding('class.app-dynamic-component') cssComponentClass = true;
  @HostBinding('class.app-dynamic-headline') cssHostClass = true;
  @Input() content: string = '';
}
