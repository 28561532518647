import {ChangeDetectionStrategy, Component, HostBinding, inject, signal} from "@angular/core";
import {MatButton} from "@angular/material/button";
import {DevBlockComponent} from "../dev/dev-block.component";
import {MatRipple} from "@angular/material/core";
import {PageEditorService} from "./page-editor.service";
import {MatDivider} from "@angular/material/divider";
import {componentConfigFactory} from "../project";

@Component({
  selector: 'page-editor-component-selector',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    DevBlockComponent,
    MatRipple,
    MatDivider
  ],
  template: `
    <mat-divider />
    <div style="
        text-align: center;
        padding-bottom: 1rem;
        ">
      Komponente hinzufügen</div>
    <div class="component-selection-container"
            [class.open]="selectorOpen()">
        <div class="component-select-button"
             (click)="addHeroTeaser()"
             mat-ripple>Hero</div>
        <div class="component-select-button"
             (click)="addMap()"
             mat-ripple>Map</div>
        <div class="component-select-button"
             (click)="addPinboard()"
             mat-ripple>Pinboard</div>
        <div class="component-select-button"
             (click)="addArticle()"
             mat-ripple>Artikel</div>
    </div>
<!--    <button (click)="toggle()" mat-button>Komponente hinzufügen</button>-->
  `
})
export class PageEditorComponentSelectorComponent {
  @HostBinding('class.page-editor-component-selector') cssCLass = true;
  private service = inject(PageEditorService);
  selectorOpen = signal(true);
  toggle() {
    // this.selectorOpen.update(value => !value);
  }

  addMap() {
    this.toggle();
    this.service.addComponent({
      ...componentConfigFactory(),
      type: 'map',
    });
  }

  addPinboard() {
    this.toggle();
    this.service.addComponent({
      ...componentConfigFactory(),
      type: 'pinboard',
    });
  }

  addHeroTeaser() {
    console.log('addHero');
    this.toggle();
    this.service.addComponent({
      ...componentConfigFactory(),
      type: 'hero-teaser',
    });
  }

  addArticle() {
    console.log('addArticle');
    this.toggle();
    this.service.addComponent({
      ...componentConfigFactory(),
      type: 'article',
    });
  }
}
